import React from 'react';
import { motion } from 'framer-motion';
import { Button } from '../../components/ui/button'
import hero1 from '../../assets/hero1.jpeg'

export default function Hero() {
  return (
    <section className="relative h-screen flex items-center justify-center overflow-hidden">
      {/* Background Image */}
      <div className="absolute inset-0 z-0">
        <img
          src={hero1}
          alt="Construction tunnel with industrial infrastructure"
          className="object-cover w-full h-full"
        />
      </div>

      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>

      {/* Content */}
      <div className="container mx-auto px-4 z-20 text-center">
        <motion.h1
          className="text-4xl md:text-6xl font-bold mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <span className="gradient-text">
            Simplifying Construction Complexities Through INTELLIGENT AI SOLUTIONS
          </span>
        </motion.h1>
        <motion.p
          className="text-xl md:text-2xl mb-8 max-w-3xl mx-auto text-white"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Empowering businesses with advanced and agile AI applications, automations, and intelligent agents.
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <Button
            size="lg"
            className="mr-4"
            onClick={() => document.getElementById('features')?.scrollIntoView({ behavior: 'smooth' })}
          >
            Discover Our Solutions
          </Button>
          <Button
            variant="secondary"
            size="lg"
            onClick={() => (window.location.href = 'https://www.icromm.com/login')}
          >
            Try Our AutoSWMS
          </Button>
        </motion.div>
      </div>

      {/* Styles */}
      <style>{`
        .gradient-text {
          background: linear-gradient(
            to right,
            #ff6b6b,
            #4ecdc4,
            #45b7d8,
            #ff6b6b
          );
          background-size: 300% 100%;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          animation: gradientFlow 8s linear infinite;
        }

        @keyframes gradientFlow {
          0% {
            background-position: 0% 50%;
          }
          100% {
            background-position: 300% 50%;
          }
        }
      `}</style>
    </section>
  );
}
