import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Button,
  LinearProgress,
} from "@mui/material";
import DragIndicator from "@mui/icons-material/DragIndicator";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { lighten, darken } from "@mui/system";
import axios from "axios";
import Cookies from "js-cookie";
import { FormContext } from "../context/FormContext";
import { useUser } from "../context/UserContext";
import ColorPickerUpdatePopup from "../components/UpdateDocument/ColorPickerUpdatePopup";
import bot from "../assets/robot.svg";

// Import components
import ProjectDetails from "../components/UpdateDocument/ProjectDetails";
import HighLevelRisksCard from "../components/UpdateDocument/HighLevelRisksCard";
import RiskManagementScheduleCard from "../components/UpdateDocument/RiskManagementScheduleCard";
import PersonsInvolvedCard from "../components/UpdateDocument/PersonsInvolvedCard";
import ConstructionPlantEquipment from "../components/UpdateDocument/ConstructionPlantEquipment";
import LegislativeAndAcops from "../components/UpdateDocument/LegislativeAndAcops";
import PPECard from "../components/UpdateDocument/PPECard";
import RequiredPermits from "../components/UpdateDocument/RequiredPermits";
import RiskTablesCard from "../components/UpdateDocument/RiskTablesCard";
import InductionSignOnAccordion from "../components/UpdateDocument/InductionSignOnAccordion";
import PeopleInvolved from "../components/UpdateDocument/PeopleInvolved";
import FormReviewer from "../components/UpdateDocument/FormReviewer";
import CopyrightContainer from "../components/UpdateDocument/CopyrightContainer";

const UpdateDocument = () => {
  const { documentId } = useParams();
  const navigate = useNavigate();
  const [color, setColor] = useState("#f5a623");
  const [documentLogo, setDocumentLogo] = useState(null);
  const { logo, updateLogo } = useUser();
  const [formData, setFormData] = useState({
    swmsTitle: "",
    workLocation: "",
    swmsNo: "",
    revNo: "",
    projectName: "",
    contractorName: "",
    date: "",
    abn: "",
    address: "",
  });
  const [openColorDialog, setOpenColorDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [messageIndex, setMessageIndex] = useState(0);
  const [componentOrder, setComponentOrder] = useState([
    "ProjectDetails",
    "HighLevelRisksCard",
    "RiskManagementScheduleCard",
    "PersonsInvolvedCard",
    "ConstructionPlantEquipment",
    "LegislativeAndAcops",
    "PPECard",
    "RequiredPermits",
    "RiskTablesCard",
    "InductionSignOnAccordion",
    "PeopleInvolved",
    "FormReviewer",
  ]);
  const { documentTitle, setDocumentTitle } = useContext(FormContext);

  const waitingMessages = [
    "Retrieving the requested information, please stand by...",
    "Processing your query with our advanced analytical tools...",
    "Compiling data for comprehensive insights, hold on a moment...",
    "Analyzing your request for a tailored response...",
    "Gathering relevant data, please wait...",
    "Synthesizing the information you need, standby...",
    "Consulting our resources to provide accurate answers...",
    "Your request is being processed by our advanced algorithms...",
    "Finalizing the analysis to ensure precise information...",
    "Cross-referencing data points for your query, please be patient...",
    "Performing in-depth data analysis, hold tight...",
    "Your query is under review by our expert system...",
    "Extracting relevant information for your request...",
    "Securing the most up-to-date data for your inquiry...",
    "Leveraging our knowledge base for your specific needs...",
    "Your request is important to us, compiling the necessary data...",
    "Optimizing our search to bring you the best results...",
    "Your question is being processed, please hold on for a moment...",
    "Running through our databases to find precise answers...",
    "Employing our state-of-the-art technology to fulfill your request...",
    "Ensuring the accuracy of our response, please be patient...",
    "Your inquiry is being prioritized, gathering information now...",
    "Activating our information retrieval systems for your query...",
    "Diligently working to bring you the requested information...",
    "Your request is in process, we appreciate your patience...",
    "Fine-tuning our response for clarity and precision...",
    "Utilizing our expertise to address your specific query...",
    "Accessing targeted information for your request...",
    "Implementing sophisticated analysis for your inquiry...",
    "Engaging our resources to respond to your important question...",
    "Preparing a detailed and accurate response, please wait...",
    "Your query is navigating through our information systems...",
    "Harnessing our digital capabilities to meet your request...",
    "Focusing our efforts on delivering your answer...",
    "Initiating a thorough search for your requested information...",
    "Evaluating the best resources to answer your question...",
    "Our systems are now processing your inquiry for detailed insights...",
    "Conducting a focused analysis to bring you accurate information...",
    "Your request is being expedited by our technology experts...",
    "Leveraging comprehensive data analysis for your query...",
    "Mobilizing our resources for an informed response...",
  ];

  const {
    allFormData,
    setAllFormData,
    ProjectDetailsData,
    setProjectDetailsData,
    highLevelRisksData,
    setHighLevelRisksData,
    riskManagementScheduleData,
    setRiskManagementScheduleData,
    personsInvolvedCardData,
    setPersonsInvolvedCardData,
    constructionPlantEquipmentData,
    setConstructionPlantEquipmentData,
    legislativeListData,
    setLegislativeListData,
    ppeCardData,
    setPPECardData,
    permitsData,
    setPermitsData,
    inductionSignOnData,
    setInductionSignOnData,
    peopleInvolvedData,
    setPeopleInvolvedData,
    formReviewerData,
    setFormReviewerData,
    riskTablesData,
    setRiskTablesData,
    documentColor,
    setDocumentColor,
    handleInductionSignOnChange,
    setDocumentData,
  } = useContext(FormContext);

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const token = Cookies.get("token");
        const response = await axios.get(
          `https://www.icromm.com/api/documents/${documentId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const documentData = response.data;
        
        setDocumentData(documentData);
        setColor(documentData.color || '#f5a623');
        setDocumentTitle(documentData.document_title || '');
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchDocument();
  }, [documentId, setDocumentData, setDocumentTitle]);

  useEffect(() => {
    if (allFormData) {
      setProjectDetailsData(allFormData.project_details || {});
      setHighLevelRisksData(allFormData.high_level_risks?.risks || []);
      setRiskManagementScheduleData(
        allFormData.risk_management_schedule?.sections || []
      );
      setPersonsInvolvedCardData(
        allFormData.persons_involved_card?.sections || []
      );
      setConstructionPlantEquipmentData(
        allFormData.construction_plant_equipment?.sections || []
      );
      setLegislativeListData(allFormData.legislative_list?.items || []);
      setPPECardData(allFormData.ppe_card?.ppeList || []);
      setPermitsData(allFormData.permits || []);
      setInductionSignOnData(allFormData.induction_sign_on || { rowsPage1: [] });
      setPeopleInvolvedData(allFormData.people_involved || {});
      setFormReviewerData(allFormData.form_reviewer || { rows_page1: [] });
      setRiskTablesData(allFormData.risk_tables || {});
      const documentColor = allFormData.color || '#f5a623';
      setColor(documentColor);
      setDocumentColor(documentColor);
      // Clear allFormData after populating the form
      setAllFormData(null);
    }
  }, [
    allFormData,
    setProjectDetailsData,
    setHighLevelRisksData,
    setRiskManagementScheduleData,
    setPersonsInvolvedCardData,
    setConstructionPlantEquipmentData,
    setLegislativeListData,
    setPPECardData,
    setPermitsData,
    setInductionSignOnData,
    setPeopleInvolvedData,
    setFormReviewerData,
    setRiskTablesData,
    setAllFormData,
    setDocumentColor,
  ]);

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const token = Cookies.get("token");
        const response = await axios.get(
          `https://www.icromm.com/api/documents/${documentId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const documentData = response.data;
        setDocumentLogo(documentData.documentLogo);
        setColor(documentData.color || "#f5a623");
        // ... set other document data ...
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    if (documentId) {
      fetchDocument();
    }
  }, [documentId]);

  const handleUpdateDocument = async () => {
    try {
      const documentData = {
        document_title: documentTitle,
        project_details: ProjectDetailsData,
        high_level_risks: {
          risks: highLevelRisksData,
          color: color,
        },
        risk_management_schedule: {
          sections: riskManagementScheduleData,
        },
        persons_involved_card: {
          sections: personsInvolvedCardData,
          color: color,
        },
        construction_plant_equipment: {
          sections: constructionPlantEquipmentData,
        },
        legislative_list: {
          items: legislativeListData,
        },
        ppe_card: {
          ppeList: ppeCardData,
        },
        permits: permitsData,
        induction_sign_on: inductionSignOnData,
        people_involved: peopleInvolvedData,
        form_reviewer: formReviewerData,
        risk_tables: riskTablesData,
        color: color,
        documentLogo: documentLogo,
      };

      const token = Cookies.get("token");

      if (!token) {
        console.error("No authentication token found");
        return;
      }

      // Make the API call to update the document
      const response = await axios.put(
        `https://www.icromm.com/api/documents/${documentId}`,
        documentData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // console.log("Document updated successfully:", response.data);
      navigate("/user-dashboard/all-documents"); 
    } catch (error) {
      console.error("Error updating document:", error);
      if (error.response) {
        console.error("Error details:", error.response.data);
      }
    }
  };

  const renderComponent = (componentName) => {
    switch (componentName) {
      case "ProjectDetails":
        return <ProjectDetails data={ProjectDetailsData} color={color} />;
      case "HighLevelRisksCard":
        return <HighLevelRisksCard color={color} />;
      case "RiskManagementScheduleCard":
        return <RiskManagementScheduleCard color={color} />;
      case "PersonsInvolvedCard":
        return <PersonsInvolvedCard color={color} />;
      case "ConstructionPlantEquipment":
        return <ConstructionPlantEquipment color={color} />;
      case "LegislativeAndAcops":
        return <LegislativeAndAcops color={color} />;
      case "PPECard":
        return <PPECard color={color} />;
      case "RequiredPermits":
        return <RequiredPermits color={color} />;
      case "RiskTablesCard":
        return <RiskTablesCard color={color} />;
      case "InductionSignOnAccordion":
        return <InductionSignOnAccordion color={color} data={inductionSignOnData} onChange={handleInductionSignOnChange} />;
      case "PeopleInvolved":
        return <PeopleInvolved color={color} />;
      case "FormReviewer":
        return <FormReviewer color={color} />;
      default:
        return null;
    }
  };

  const handleColorChange = (newColor) => {
    setDocumentColor(newColor.hex);
  };

  const handleOpenColorDialog = () => {
    setOpenColorDialog(true);
  };

  const handleCloseColorDialog = () => {
    setOpenColorDialog(false);
  };

  const MAX_FILE_SIZE = 1024 * 1024;

  const handleIconChange = async (e) => {
    const file = e.target.files[0];
    
    if (file) {
      // Check file type
      if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
        alert('Please upload only PNG or JPEG images');
        return;
      }
  
      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        alert('File size must be less than 1MB');
        return;
      }
  
      try {
        // Create form data for upload
        const formData = new FormData();
        formData.append('file', file);
  
        // Upload to Cloudinary through your backend
        const uploadResponse = await fetch('https://www.icromm.com/api/upload-logo', {
          method: 'POST',
          body: formData,
        });
  
        if (!uploadResponse.ok) {
          const errorData = await uploadResponse.json();
          throw new Error(errorData.detail || 'Failed to upload image');
        }

        const uploadResult = await uploadResponse.json();
        setDocumentLogo(uploadResult.url);

        // Get dominant color if needed
        // const colorResponse = await fetch(
        //   "https://www.icromm.com/api/get_dominant_color",
        //   {
        //     method: "POST",
        //     body: formData,
        //   }
        // );

        // if (colorResponse.ok) {
        //   const result = await colorResponse.json();
        //   const [r, g, b] = result.dominant_color;
        //   const dominantColor = `#${((1 << 24) + (r << 16) + (g << 8) + b)
        //     .toString(16)
        //     .slice(1)
        //     .toUpperCase()}`;
        //   setColor(dominantColor);
        // }
      } catch (error) {
        console.error("Error handling image upload:", error);
      }
    }
  };

  return (
    <Box>
      <Box sx={{ mr: 2 }}>
        <style>
          {`
                    .hide-scrollbar {
                        -ms-overflow-style: none;
                        scrollbar-width: none;
                    }
                    .hide-scrollbar::-webkit-scrollbar {
                        display: none;
                    }
                `}
        </style>

        <Box
          className="hide-scrollbar"
          sx={{
            height: "100vh",
            overflowY: "scroll",
          }}
        >
          {/* <IconButton
            onClick={handleUpdateDocument}
            sx={{
              position: "relative",
              top: 10,
              left: 10,
              backgroundColor: "white",
              "&:hover": {
                backgroundColor: "lightgrey",
              },
            }}
          >
            <ArrowBackIcon />
          </IconButton> */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: documentColor,
              p: 2,
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              border: `2px solid ${documentColor}`,
              mb: 2,
              minHeight: "160px",
              mt: 2,
              mx: 2,
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 10,
                right: openColorDialog ? 25 : 10,
                cursor: "pointer",
              }}
            >
              <IconButton onClick={handleOpenColorDialog}>
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    backgroundColor: documentColor,
                    border: "2px solid #DDDDDD",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      "& .MuiSvgIcon-root": {
                        opacity: 1,
                      },
                    },
                  }}
                >
                  <HistoryEduIcon
                    sx={{
                      color: "black",
                      opacity: 0,
                    }}
                  />
                </Box>
              </IconButton>
              <ColorPickerUpdatePopup
                open={openColorDialog}
                color={documentColor} // Use documentColor instead of local color state
                onColorChange={handleColorChange}
                onClose={handleCloseColorDialog}
                setLoading={setLoading}
                setPercentage={setPercentage}
                handleUpdateDocument={handleUpdateDocument}
              />
            </Box>

            <Box sx={{ display: "flex" }}>
              {/* Left Section with Uploadable Icon */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: `2px solid ${documentColor}`,
                  pr: 2,
                  height: "160px",
                  minWidth: "200px",
                  width: "35%",
                  overflow: "hidden",
                  cursor: "pointer",
                  background: "white",
                  borderRadius: "5px",
                  position: "relative",
                }}
              >
                <input
                  accept="image/*"
                  type="file"
                  id="icon-upload"
                  style={{ display: "none" }}
                  onChange={handleIconChange}
                />
                <label htmlFor="icon-upload">
                  <IconButton component="span">
                    <img
                      src={documentLogo || "https://asset.cloudinary.com/dk2lflesi/9e0f9aa5de08085e83b56d2eab297bbd"}
                      alt="Document Logo"
                      style={{
                        height: "135px",
                        width: "auto",
                        maxWidth: "100%",
                        objectFit: "contain",
                        borderRadius: "5px",
                      }}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://asset.cloudinary.com/dk2lflesi/9e0f9aa5de08085e83b56d2eab297bbd";
                      }}
                    />
                  </IconButton>
                </label>
              </Box>

              {/* Right Section with Title */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  ml: 5,
                  mr: 6,
                  width: "65%",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Roboto, sans-serif",
                    color: "#000",
                  }}
                >
                  UPDATE SAFE WORK METHOD STATEMENT
                </Typography>

                <TextField
                  variant="outlined"
                  placeholder="Enter document title"
                  value={documentTitle}
                  onChange={(e) => setDocumentTitle(e.target.value)}
                  fullWidth
                  sx={{
                    mt: 1,
                    backgroundColor: "#fff",
                    fontFamily: "Roboto, sans-serif",
                    borderRadius: "5px",
                  }}
                  InputProps={{
                    sx: {
                      height: "40px",
                    },
                  }}
                />
                {loading && (
                  <Box sx={{ width: "100%", mt: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ flexGrow: 1, mr: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={(percentage / 7) * 100}
                          sx={{
                            borderRadius: "25px",
                            border: "2px solid #665A48",
                            height: "20px",
                            bgcolor: darken(color, 0.1),
                            boxShadow: "inset 0 2px 5px rgba(0, 0, 0, 0.15)",
                            "& .MuiLinearProgress-bar": {
                              borderRadius: "25px",
                              backgroundColor: lighten(documentColor, 0.2),
                              boxShadow: 5,
                              borderRight: "2px solid #665A48",
                            },
                          }}
                        />
                      </Box>
                      <Typography variant="body1">{percentage}/7</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ m: 2 }}>
            {componentOrder.map((componentName, index) => (
              <Box
                key={componentName}
                sx={{ mb: 2, display: "flex", alignItems: "center" }}
              >
                <IconButton
                  sx={{
                    cursor: "grab",
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                >
                  <DragIndicator />
                </IconButton>
                <Box sx={{ width: "100%" }}>
                  {renderComponent(componentName)}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <CopyrightContainer contractorName={formData.contractorName} />
    </Box>
  );
};

export default UpdateDocument;
