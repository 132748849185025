import { useState, useEffect, useRef } from 'react'
import { Bot, Zap, Cpu, FileText, ChevronLeft, ChevronRight } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'

const features = [
  {
    icon: Cpu,
    title: 'Advanced and Agile AI Applications',
    description: 'Empower your construction business with adaptable AI solutions that enhance efficiency and accuracy.'
  },
  {
    icon: Zap,
    title: 'Automations',
    description: 'Streamline your documentation processes through automation, reducing manual effort and errors.'
  },
  {
    icon: Bot,
    title: 'Intelligent Agents',
    description: 'Leverage intelligent agents to provide real-time guidance and support in documentation tasks.'
  }
]

const slides = [
  {
    id: "advanced-documentation",
    title: "Advancing Construction Documentation with Advanced Intelligence and Human Expertise",
    content: [
      "At ICROMM, we simplify the complexities of construction documentation by enhancing the systems you already use. By integrating advanced technology, automation, and intelligent agents, we streamline time-consuming tasks like compliance checks, document updates, and form completion—letting your tools do the boring stuff while your team focuses on the worksite.",
      "Our solutions seamlessly integrate with your existing workflows, combining AI-driven insights with industry expertise. From pre-filling forms to analysing trends, flagging risks, and suggesting improvements, we ensure your processes are smarter, faster, and more accurate. Our technology doesn't replace human expertise; it empowers it, with systems designed to work alongside professionals to deliver polished, reliable results.",
      "With tools like our <strong>AutoDoc SWMS</strong>—currently in beta testing—we're taking document automation to the next level. This intelligent platform generates critical documents like Safe Work Method Statements (SWMS) in minutes, adhering to the latest standards with exceptional precision. Whether it's automating reports, managing inspections, or updating training records, ICROMM's innovations reduce the hassle of admin while giving you greater control and confidence."
    ]
  },
  {
    id: "ai-powers-hse",
    title: "How AI Powers HSE in Construction",
    content: [
      "Health, Safety, and Environmental (HSE) management is a cornerstone of successful construction projects, but managing the documentation can be time-consuming and error-prone. Our AI-driven systems streamline the creation of HSE documents, including safety permits, SWMS, environmental monitoring forms, hazard identification records, and more. By automating these processes, we enable teams to focus on implementing safety measures and maintaining environmental compliance rather than being bogged down in paperwork.",
      "AI also enhances accuracy by cross-referencing data with the latest regulations and standards, ensuring every document is compliant and up-to-date. It can generate tailored pre-start and toolbox talk forms, incorporate psychosocial health factors, and identify potential risks through intelligent analysis of project data. These systems not only save time but also empower project teams to proactively manage safety and environmental risks, contributing to safer, more efficient worksites.",
      "By leveraging AI for HSE, construction projects gain a powerful tool to reduce administrative burdens, improve compliance, and create healthier, safer environments for workers and communities alike."
    ]
  },
  {
    id: "smarter-work-flow",
    title: "How We Deliver Smarter Workflow",
    content: [
      "<strong>We Develop APIs:</strong> Build APIs and intelligent agents that integrate seamlessly with your existing platforms, adding smart capabilities without the need for system replacements.",
      "<strong>Real-Time Dashboards:</strong> Deliver visual insights such as safety trends, budget risks, and performance metrics, saving time on manual analysis and enabling data-driven decisions.",
      "<strong>Mobile-Friendly Tools:</strong> Automation and AI-driven solutions are designed to work across all your devices. These tools enable on-site staff to input minimal data, while the system completes the rest intelligently.",
      "<strong>AI-Powered Assistants:</strong> Leverage AI-driven assistants for quick access to critical information such as safety protocols, equipment requirements, or document statuses.",
      "<strong>Data Standardisation:</strong> Ensure all tools adhere to standardised formats, facilitating easy integration, improved consistency, and streamlined workflows.",
      "<strong>Private and Secure Agents:</strong> Our agents and bots operate securely within your internal intranet or designated systems, safeguarding sensitive data and maintaining privacy."
    ]
  },
  {
    id: "finance-and-commercial",
    title: "How Our AI Streamlines Finance and Commercial Documentation",
    content: [
      "Managing finance and commercial documents in construction projects is often a time-consuming process, requiring precision to maintain budgets, track changes, and manage contractual obligations. Our AI-driven solutions automate the creation, analysis, and management of key documents like timesheets, extension of time forms, site diaries, and variation records. By handling these tasks efficiently, our AI saves time, reduces errors, and ensures alignment with project requirements.",
      "For finance documents, our AI automates the generation of dockets, purchase requests, and project costing forms. It compares planned vs. actual costing sheets, identifying discrepancies and providing actionable insights to help manage budgets effectively. Timesheet accuracy is improved through integration with on-site systems, aligning labour costs with real project progress while reducing manual errors.",
      "In the commercial realm, our AI tracks and documents changes to schedules or budgets, streamlines contractor pre-qualifications, and simplifies the management of claims forms, meeting minutes, and site instructions. By automating these processes, our AI reduces administrative burdens, ensures compliance, and helps keep projects on track with minimal disruption."
    ]
  },
  {
    id: "quality-and-production",
    title: "How Our AI Enhances Quality and Production Documentation",
    content: [
      "Managing quality and production documentation is essential for successful construction projects, but it can be time-consuming. Our AI-driven solutions simplify the creation of critical documents like defect management records, inspection and test plans (ITPs), and daily reports. By automating these processes, our AI reduces administrative workloads and ensures consistent, accurate documentation aligned with project standards.",
      "For quality documents, our AI automates the generation of punch lists, lot management sheets, and corrective action reports, helping teams identify and resolve defects efficiently. It streamlines hold points, witness points, and test requests, ensuring inspections and approvals are handled accurately and without delays. Quality checklists, RFIs, and reports are created quickly and precisely, keeping teams on track with compliance requirements.",
      "In production, our AI supports daily operations by automating shift reports, materials inspections, and comparisons of planned vs. actual quantities. Waste tracking and budgeting are simplified with AI-generated insights, helping teams optimise resources and control costs effectively. With streamlined daily reporting, key data is captured and shared in real time, enabling better decision-making.",
      "Our AI-driven tools integrate seamlessly with existing systems, allowing teams to focus on delivering quality results while reducing waste and improving overall project efficiency."
    ]
  }
]

export default function Feature() {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slideDirection, setSlideDirection] = useState('right')
  const featuresRef = useRef(null)

  const goToSlide = (index) => {
    setSlideDirection(index > currentSlide ? 'right' : 'left')
    setCurrentSlide(index)
  }

  const nextSlide = () => {
    setSlideDirection('right')
    setCurrentSlide((prev) => (prev + 1) % slides.length)
  }

  const prevSlide = () => {
    setSlideDirection('left')
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length)
  }

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1)
      if (hash === 'features') {
        const slideIndex = slides.findIndex(slide => slide.id === 'advanced-documentation')
        if (slideIndex !== -1) {
          goToSlide(slideIndex)
          featuresRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
      } else {
        const slideIndex = slides.findIndex(slide => slide.id === hash)
        if (slideIndex !== -1) {
          goToSlide(slideIndex)
          featuresRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }

    window.addEventListener('hashchange', handleHashChange)
    handleHashChange() // Handle initial hash on page load

    return () => window.removeEventListener('hashchange', handleHashChange)
  }, [])

  return (
    <section id="features" ref={featuresRef} className="py-20 bg-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12 text-white">Our AI-Powered Solutions</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="bg-gray-700 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <feature.icon className="w-12 h-12 text-yellow-400 mb-4" />
              <h3 className="text-xl font-semibold mb-2 text-white">{feature.title}</h3>
              <p className="text-gray-300">{feature.description}</p>
            </motion.div>
          ))}
        </div>
        <AnimatePresence mode="wait">
          <motion.div
            key={currentSlide}
            id={slides[currentSlide].id}
            className="p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow relative overflow-hidden"
            initial={{ opacity: 0, x: slideDirection === 'right' ? 20 : -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: slideDirection === 'right' ? -20 : 20 }}
            transition={{ duration: 0.3 }}
            style={{
              background: 'radial-gradient(circle at top left, rgb(55, 65, 81) 70%, rgb(64, 45, 107))'
            }}
          >
            <button 
              onClick={prevSlide} 
              className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white hover:text-yellow-400 transition-colors"
              aria-label="Previous slide"
            >
              <ChevronLeft className="w-8 h-16" />
            </button>
            <button 
              onClick={nextSlide} 
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white hover:text-yellow-400 transition-colors"
              aria-label="Next slide"
            >
              <ChevronRight className="w-8 h-16" />
            </button>
            <div className="flex items-start mb-4">
              <FileText className="w-12 h-12 text-yellow-400 mr-4 flex-shrink-0" />
              <h3 className="text-2xl font-semibold text-white">
                {slides[currentSlide].title}
              </h3>
            </div>
            <div className="text-gray-300 space-y-4 px-4 md:px-10 max-h-[60vh] overflow-y-auto">
              {slides[currentSlide].content.map((paragraph, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
              ))}
            </div>
          </motion.div>
        </AnimatePresence>
        <div className="flex justify-center mt-4 space-x-2">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`w-3 h-3 rounded-full border border-white ${
                currentSlide === index ? 'bg-white' : 'bg-transparent'
              }`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </div>
    </section>
  )
}