import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronDown, ChevronUp, Circle, Maximize2, Minimize2, Grid, FileText, Mail } from 'lucide-react'

const Section = ({ title, children, isOpen, toggle }) => (
  <motion.div
    initial={false}
    animate={{ backgroundColor: isOpen ? "rgba(59, 130, 246, 0.1)" : "rgba(30, 41, 59, 0.4)" }}
    className="mb-4 rounded-lg overflow-hidden"
  >
    <motion.button
      onClick={toggle}
      className="w-full p-4 flex justify-between items-center text-left font-semibold"
      whileHover={{ backgroundColor: "rgba(59, 130, 246, 0.2)" }}
    >
      {title}
      {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
    </motion.button>
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 }
          }}
          transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          <div className="p-4 text-sm">{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  </motion.div>
)

const Blockquote = ({ children }) => (
  <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-900 p-4 my-4 rounded">
    {children}
  </div>
)

export default function Guide() {
  const [openSection, setOpenSection] = useState(null)

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index)
  }

  const features = [
    {
      title: "1. Logo",
      content: "Clicking on the logo area will open a dialogue window where you can choose a new logo to upload. The file size must be less than 1 MB. Only PNG, JPG or JPEG files are accepted.",
      icon: <img src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/icrommLogoWhiteTop-YoKxADhidUCxm0p8hfxDqV1OMy6qVP.png" alt="Icromm Logo" className="h-auto w-auto max-h-7 max-w-7" />
    },
    {
      title: "2. Colour",
      content: <>
        <p>Clicking the circle in the top right of the screen will open the <strong>Command Dialogue</strong> window. Once open, you can see an icon on the top right that looks like an artist's palette. Clicking this opens a colour picker tool where you can set the colour of the form to your liking. Once you have chosen a colour you're happy with, simply click the palette icon again to close the colour picker dialogue.</p>
        <p>Click the 'X' next to the colour palette to close the <strong>Command Dialogue</strong>.</p>
      </>,
      icon: <Circle size={24} />
    },
    {
      title: "3. AutoDoc Sections",
      content: <p>There are 12 sections that make up the AutoDoc form. You can rearrange these to your liking. To do this, click and hold on the <strong>grabber icon</strong> and drag the section to another area on the form. Release when you are over another grabber icon to replace it with the one you are moving.</p>,
      icon: <Grid size={24} />
    },
    {
      title: "4. Maximising and Minimising",
      content: "You can maximise and minimise each section so you can focus your work without having all the cards open, making navigation easier. By default, each section is empty and has just one sub-card present. These will be populated by the AutoDoc when you run the program.",
      icon: <Maximize2 size={24} />
    },
    {
      title: "5. Project Details",
      content: <p>Be sure to fill in the <strong>Project Details</strong> section; while it has no bearing on the outcome of the AutoDoc functionality, it helps organise your documents in your dashboard for later use.</p>,
      icon: <FileText size={24} />
    }
  ]

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-blue-900 text-white p-8 m-[-27px]">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-4xl mx-auto"
      >
        <div className="flex items-center gap-4 mb-8">
          <img
            src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/icrommLogoWhiteTop-YoKxADhidUCxm0p8hfxDqV1OMy6qVP.png"
            alt="Icromm Logo"
            className="w-16 h-auto"
          />
          <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            Working with the SWMS AutoDOC
          </h1>
        </div>

        <p className="text-xl mb-8">
          There are many features that you can modify to make the document more in line with your company's branding.
        </p>

        {features.map((feature, index) => (
          <Section
            key={index}
            title={
              <div className="flex items-center gap-4">
                {feature.icon}
                <span>{feature.title}</span>
              </div>
            }
            isOpen={openSection === index}
            toggle={() => toggleSection(index)}
          >
            {feature.content}
          </Section>
        ))}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="mt-12"
        >
          <h2 className="text-2xl font-bold mb-4">Running the AutoDoc for the First Time</h2>
          <p className="mb-4">The process for running the program is straightforward.</p>

          <Section
            title="1. Command Dialogue"
            isOpen={openSection === 'command'}
            toggle={() => toggleSection('command')}
          >
            <p>Clicking the circle in the top right of the screen will open the <strong>Command Dialogue</strong>. Here you will see five sections:</p>
            <ul className="list-disc list-inside mt-2">
              <li>Task Details</li>
              <li>Location and Site Details</li>
              <li>Must Include Tasks (Optional)</li>
              <li>Must Exclude Tasks (Optional)</li>
              <li>Number of Activities</li>
            </ul>
            <Blockquote>
              <p><strong>NB:</strong> <strong>Number of Activities</strong> controls the <strong>Risk Management Schedule</strong> section only. The number you choose here will tell the AutoDoc how many activities you want returned. At the moment, you can choose a number between 12 and 26.</p>
            </Blockquote>
            <p className="mt-2">These five sections are very important and should be filled in with care. While the AI is trained in construction in Australia and has a high level of knowledge about <strong>safe work</strong> in Australia, it knows nothing about the specifics of your construction site and what is important to your site.</p>
          </Section>

          <Section
            title="2. Filling in the Commands"
            isOpen={openSection === 'filling'}
            toggle={() => toggleSection('filling')}
          >
            <p>Each of the five sections has placeholder text to give you an idea of what to write in each section. The more detailed you are, the better your returned SWMS will be. Over time, you will find that working with the AutoDoc is very simple. Here is an example of a basic set of instructions:</p>
            <Blockquote>
              <p><strong>1. Task Details</strong><br />
              Construct and erect timber formwork and bracing for a bridge abutment. A crawler crane will be used to lift formwork panels and braces into position, to then be fixed by workers.</p>
              <p><strong>2. Location and Site Details</strong><br />
              Inside a narrow rail corridor with live electric trains in operation. Work is in Brisbane, Queensland, during the summer. Boilermakers will be building metal bracing using an oxyacetylene torch, stick welding, and grinding and cutting disk power tools.</p>
              <p><strong>3. Must Include Tasks (Optional)</strong><br />
              Safely working with trucks, safely using power tools, working within railway zones, working during active train schedules, entry and exit of QR areas.</p>
              <p><strong>4. Must Exclude Tasks (Optional)</strong><br />
              Concrete work, working near water, confined spaces work, scaffolding work.</p>
              <p><strong>5. Number of Activities</strong><br />
              Write a number between 12 and 26. The default is 22.</p>
            </Blockquote>
          </Section>

          <Section
            title="3. Running the AutoDoc"
            isOpen={openSection === 'running'}
            toggle={() => toggleSection('running')}
          >
            <p>Once you have filled in the sections, you can run the program. Clicking the <strong>Submit</strong> button will start the process. You can then close the <strong>Command Dialogue</strong> by clicking the <strong>Close</strong> button.</p>
            <p className="mt-2">It takes around 10 minutes for the AutoDoc to complete the process, and we are working to reduce this time. A document with just 16 <strong>Risk Management Schedules</strong> returns over 5,000 words for the complete AutoDoc.</p>
            <p className="mt-2 font-bold">You can track the progress in the main header of the document as the AI considers all possible responses to your queries and instructions. The document is complete when all seven phases under the hood are finished, and the progress bar is no longer displayed.</p>
          </Section>

          <Section
            title="4. Editing"
            isOpen={openSection === 'editing'}
            toggle={() => toggleSection('editing')}
          >
            <p>Once the process is complete, you are able to further modify your document.</p>
            <p className="mt-2">Clicking the text in each text input area allows you to further modify what has been returned by the AutoDoc.</p>
            <p className="mt-2"><strong>1. Subsections</strong><br />
            Clicking the icon on the left of each of the cards opens a small <strong>control panel</strong> for that card. Here, you can delete subsections you may not want and also add new subsections as needed. This process is the same for all cards in the AutoDoc.</p>
          </Section>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8, duration: 0.5 }}
          className="mt-12 text-center"
        >
          <h2 className="text-2xl font-bold mb-4">Need Support?</h2>
          <p>If you require help, please don't hesitate to reach out:</p>
          <a
            href="mailto:support@icromm.com"
            className="inline-flex items-center gap-2 mt-4 px-6 py-3 bg-blue-600 hover:bg-blue-700 rounded-full transition-colors duration-200"
          >
            <Mail size={20} />
            Contact Support
          </a>
        </motion.div>
      </motion.div>
    </div>
  )
}