import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-2xl font-bold mb-4 text-yellow-400">ICROMM</h3>
            <p className="text-gray-400">
              Transforming construction workflows with AI-powered solutions that integrate seamlessly with your existing systems.
            </p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4 text-yellow-400">Quick Links</h4>
            <ul className="space-y-2">
              <li><a href="#ai-docs" className="hover:text-yellow-400 transition-colors">Our AI-Powered Solutions</a></li>
              <li><a href="#features" className="hover:text-yellow-400 transition-colors">Advanced Documentation</a></li>
              <li><a href="#features" className="hover:text-yellow-400 transition-colors">AI Powers HSE</a></li>
              <li><a href="#features" className="hover:text-yellow-400 transition-colors">Smarter Work Flow</a></li>
              <li><a href="#features" className="hover:text-yellow-400 transition-colors">Finance and Commercial</a></li>
              <li><a href="#features" className="hover:text-yellow-400 transition-colors">Quality and Production</a></li>
            </ul>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4 text-yellow-400">Contact</h4>
            <p className="text-gray-400">Want to know more?</p>
            <p className="text-gray-400 mb-2">Contact: info@icromm.com</p>
            <p className="text-gray-400">Need help with an ICROMM product?</p>
            <p className="text-gray-400">Contact: support@icromm.com</p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4 text-yellow-400">Latest Insights</h4>
            <ul className="space-y-2">
              <li>
                <a 
                  href="https://infrastructuremagazine.com.au/construction-industry-report-finds-rapid-rise-in-ai/" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-gray-400 hover:text-yellow-400 transition-colors"
                >
                  "Put simply, construction companies need to get their ducks in a row now..."
                </a>
              </li>
              <li>
                <a 
                  href="https://itbrief.com.au/story/ai-use-surges-in-australia-s-construction-industry#:~:text=It%20shows%20that%2030%25%20of,as%20higher%20material%20costs%2C%20labour" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-gray-400 hover:text-yellow-400 transition-colors"
                >
                  "AI use surges in Australia's construction industry..."
                </a>
              </li>
              <li>
                <a 
                  href="https://news.microsoft.com/en-au/features/copiloting-construction-john-holland-embraces-generative-ai-to-enhance-the-productivity-of-its-workforce/" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-gray-400 hover:text-yellow-400 transition-colors"
                >
                  "John Holland is leveraging generative AI tools..."
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-800 text-center relative">
          <div className="absolute inset-x-0 top-0 h-4 bg-gradient-to-t from-transparent to-[rgb(10,15,25)]"></div>
          <p className="text-gray-400 text-xs">
            © 2024 ICROMM Pty Ltd. All rights reserved.
          </p>
          <p className="text-gray-400 text-xs mt-2">
            ICROMM acknowledges the Traditional Owners of the lands on which we work and pays respect to their Elders, past and present.
          </p>
        </div>
      </div>
    </footer>
  );
}
