import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Button,
  LinearProgress,
} from "@mui/material";
import { SketchPicker } from "react-color";
import PaletteIcon from "@mui/icons-material/Palette";
import DragIndicator from "@mui/icons-material/DragIndicator";
import axios from "axios"; // Assuming you're using axios for API requests
import qs from "qs"; // Import qs to properly encode form data
import { Settings2 } from "lucide-react";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { lighten } from "@mui/system";
import { darken } from "@mui/system";
import bot from "../assets/robot.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedImage } from '@cloudinary/react';

// Import components
import ProjectDetails from "../components/ProjectDetails";
import HighLevelRisksCard from "../components/HighLevelRisksCard";
import RiskManagementScheduleCard from "../components/RiskManagementScheduleCard";
import PersonsInvolvedCard from "../components/PersonsInvolvedCard";
import ConstructionPlantEquipment from "../components/ConstructionPlantEquipment";
// import uploadimg from "../assets/icrommLogoBlueLarge.png";
import "@fontsource/roboto";
import LegislativeAndAcops from "../components/LegislativeAndAcops";
import PPECard from "../components/PPECard";
import RequiredPermits from "../components/RequiredPermits";
import RiskTablesCard from "../components/RiskTablesCard";
import InductionSignOnAccordion from "../components/InductionSignOnAccordion";
import PeopleInvolved from "../components/PeopleInvolved";
import FormReviewer from "../components/FormReviewer";
import CopyrightContainer from "../components/CopyrightContainer";
import ColorPickerAndApiModal from "../components/ColorPickerAndApiModal";
import { FormContext } from "../context/FormContext";
import { useUser } from "../context/UserContext";

const LandingPage = () => {

  const uploadimg = "https://res.cloudinary.com/dk2lflesi/image/upload/fl_preserve_transparency/v1730193738/documents/pskqgdyqgtalvm0krlxs.jpg?_s=public-apps";

  const [icon, setIcon] = useState(uploadimg); // Default icon path
  const [color, setColor] = useState("#f5a623"); // Default background color
  const { logo, updateLogo } = useUser(); // logo image change
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [q1, setQ1] = useState(null);
  const [q2, setQ2] = useState(null);
  const [q3, setQ3] = useState(null);
  const [q4, setQ4] = useState(null);
  const [q5, setQ5] = useState(null);
  const [q6, setQ6] = useState(null);
  const [q7, setQ7] = useState(null);
  const [componentOrder, setComponentOrder] = useState([
    "ProjectDetails",
    "HighLevelRisksCard",
    "RiskManagementScheduleCard",
    "PersonsInvolvedCard",
    "ConstructionPlantEquipment",
    "LegislativeAndAcops",
    "PPECard",
    "RequiredPermits",
    "RiskTablesCard",
    "InductionSignOnAccordion",
    "PeopleInvolved",
    "FormReviewer",
  ]);
  const [formData, setFormData] = useState({
    swmsTitle: "",
    workLocation: "",
    swmsNo: "",
    revNo: "",
    projectName: "",
    contractorName: "",
    date: "",
    abn: "",
    address: "",
    document_title: "",
  });
  const [percentage, setPercentage] = useState(0);
  const [messageIndex, setMessageIndex] = useState(0);
  const waitingMessages = [
    "Retrieving the requested information, please stand by...",
    "Processing your query with our advanced analytical tools...",
    "Compiling data for comprehensive insights, hold on a moment...",
    "Analyzing your request for a tailored response...",
    "Gathering relevant data, please wait...",
    "Synthesizing the information you need, standby...",
    "Consulting our resources to provide accurate answers...",
    "Your request is being processed by our advanced algorithms...",
    "Finalizing the analysis to ensure precise information...",
    "Cross-referencing data points for your query, please be patient...",
    "Performing in-depth data analysis, hold tight...",
    "Your query is under review by our expert system...",
    "Extracting relevant information for your request...",
    "Securing the most up-to-date data for your inquiry...",
    "Leveraging our knowledge base for your specific needs...",
    "Your request is important to us, compiling the necessary data...",
    "Optimizing our search to bring you the best results...",
    "Your question is being processed, please hold on for a moment...",
    "Running through our databases to find precise answers...",
    "Employing our state-of-the-art technology to fulfill your request...",
    "Ensuring the accuracy of our response, please be patient...",
    "Your inquiry is being prioritized, gathering information now...",
    "Activating our information retrieval systems for your query...",
    "Diligently working to bring you the requested information...",
    "Your request is in process, we appreciate your patience...",
    "Fine-tuning our response for clarity and precision...",
    "Utilizing our expertise to address your specific query...",
    "Accessing targeted information for your request...",
    "Implementing sophisticated analysis for your inquiry...",
    "Engaging our resources to respond to your important question...",
    "Preparing a detailed and accurate response, please wait...",
    "Your query is navigating through our information systems...",
    "Harnessing our digital capabilities to meet your request...",
    "Focusing our efforts on delivering your answer...",
    "Initiating a thorough search for your requested information...",
    "Evaluating the best resources to answer your question...",
    "Our systems are now processing your inquiry for detailed insights...",
    "Conducting a focused analysis to bring you accurate information...",
    "Your request is being expedited by our technology experts...",
    "Leveraging comprehensive data analysis for your query...",
    "Mobilizing our resources for an informed response...",
  ];
  // const [swmsTitle, setSwmsTitle] = useState('');
  // const [workLocation, setWorkLocation] = useState('');
  const [loading, setLoading] = useState(false); // Track loading state
  // const [responseReceived, setResponseReceived] = useState(false); // Track if API response has been received
  // const [swmsTitleBlurred, setSwmsTitleBlurred] = useState(false); // Check if the user has finished editing swmsTitle
  // const [workLocationBlurred, setWorkLocationBlurred] = useState(false); // Check if the user has finished editing workLocation
  const [openColorDialog, setOpenColorDialog] = useState(false);
  const { documentColor, setDocumentColor, documentTitle, setDocumentTitle } = useContext(FormContext);

  useEffect(() => {
    let messageInterval;
    if (loading) {
      // Change message every 5 seconds
      messageInterval = setInterval(() => {
        setMessageIndex((prev) => (prev + 1) % waitingMessages.length);
      }, 5000);

      return () => {
        clearInterval(messageInterval);
      };
    } else {
      clearInterval(messageInterval);
    }
  }, [loading]);

  const handleColorChange = (newColor) => {
    setDocumentColor(newColor); // Update the color in FormContext
  };

  // const handleBlur = (fieldName) => {
  //     if (fieldName === 'swmsTitle') {
  //         setSwmsTitleBlurred(false);
  //     }
  //     if (fieldName === 'workLocation') {
  //         setWorkLocationBlurred(false);
  //     }
  // };

  // const handleFocus = (fieldName) => {
  //     if (fieldName === 'swmsTitle') {
  //         setSwmsTitleBlurred(true);
  //     }
  //     if (fieldName === 'workLocation') {
  //         setWorkLocationBlurred(true);
  //     }
  // };

  // useEffect(() => {
  //     // Logging all the relevant variables for debugging
  //     console.log('Effect triggered');
  //     console.log('swmsTitleBlurred:', swmsTitleBlurred);
  //     console.log('workLocationBlurred:', workLocationBlurred);
  //     console.log('formData.swmsTitle:', formData.swmsTitle);
  //     console.log('formData.workLocation:', formData.workLocation);
  //     console.log('swmsTitle:', swmsTitle);
  //     console.log('workLocation:', workLocation);

  //     if (!swmsTitleBlurred &&
  //         !workLocationBlurred &&
  //         formData.swmsTitle &&
  //         formData.workLocation &&
  //         ((formData.swmsTitle !== swmsTitle) ||
  //         (formData.workLocation !== workLocation))) {

  //         console.log('API call initiated'); // Log when the API call is about to be made
  //         setLoading(true); // Set loading to true while making the API request
  //         setSwmsTitle(formData.swmsTitle);
  //         setWorkLocation(formData.workLocation);

  //         // Encode form data using qs to match 'application/x-www-form-urlencoded'
  //         const postData = qs.stringify({
  //             task: formData.swmsTitle,
  //             location: formData.workLocation
  //         });

  //         axios.post(`${process.env.REACT_APP_API_URL}/process_questions/`, postData, {
  //             headers: {
  //                 'Content-Type': 'application/x-www-form-urlencoded'
  //             }
  //         })
  //         .then(response => {
  //             console.log('API Response:', response.data); // Log API response for debugging
  //         })
  //         .catch(error => {
  //             console.error('API Error:', error); // Log API error for debugging
  //         })
  //         .finally(() => {
  //             setLoading(false); // Set loading to false after response
  //             setSwmsTitleBlurred(false);  // Reset the blur state after API call
  //             setWorkLocationBlurred(false); // Reset the blur state after API call
  //             console.log('API call completed, loading set to false'); // Log completion
  //         });
  //     } else {
  //         console.log('Conditions not met for API call'); // Log when conditions are not met
  //     }
  // }, [swmsTitleBlurred, workLocationBlurred, formData.swmsTitle, formData.workLocation]);

  const [draggingIndex, setDraggingIndex] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOpenColorDialog = () => {
    setOpenColorDialog(true);
  };

  const handleCloseColorDialog = () => {
    setOpenColorDialog(false);
  };

  //   const handleIconChange = (e) => {
  //     const file = e.target.files[0];
  //     if (file) {
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         setIcon(reader.result); // Set the icon to the uploaded image
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   };

  const handleIconChange = async (e) => {
    const file = e.target.files[0];
    const defaultLogo = "https://www.icromm.com/static/media/icrommLogoBlueLarge.ec96e9b4a1dce443c781.png";
  
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'xpebv8k3'); 
  
      try {
        const response = await axios.post('https://api.cloudinary.com/v1_1/dk2lflesi/image/upload', formData);
        const imageUrl = response.data.secure_url;
        setIcon(imageUrl); // Set the icon to the uploaded image URL
        updateLogo(imageUrl); // Update the logo in UserContext
      } catch (error) {
        console.error('Error uploading image to Cloudinary:', error);
        // If there's an error, use the default logo
        setIcon(defaultLogo);
        updateLogo(defaultLogo);
      }
    } else {
      // If no file is selected, use the default logo
      setIcon(defaultLogo);
      updateLogo(defaultLogo);
    }
  };

  const handleHighLevelRiskChange = (index, value) => {
    setFormData((prevData) => ({
      ...prevData,
      highLevelRisks: prevData.highLevelRisks.map((risk, i) =>
        i === index ? value : risk
      ),
    }));
  };

  const handleRiskManagementChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      riskManagementSchedule: {
        ...prevData.riskManagementSchedule,
        [field]: value,
      },
    }));
  };

  const handleDragStart = (index, e) => {
    e.dataTransfer.setData("text/plain", index);
    e.currentTarget.style.opacity = "0.9";
    setDraggingIndex(index);
  };

  const handleDragEnd = (e) => {
    if (e.currentTarget) {
      e.currentTarget.style.opacity = "1";
    }
    setDraggingIndex(null);
  };

  const handleDrop = (index, e) => {
    e.preventDefault();
    // e.currentTarget.style.opacity = "1";
    const draggedIndex = e.dataTransfer.getData("text/plain");
    if (draggedIndex === index) return;

    const newOrder = Array.from(componentOrder);
    newOrder.splice(index, 0, newOrder.splice(draggedIndex, 1)[0]);
    setComponentOrder(newOrder);
    setDraggingIndex(null);
  };

  const handleDragOver = (e) => {
    // e.currentTarget.style.opacity = "1";
    e.preventDefault();
  };

  // const handlePrev = (index) => {
  //     if (index > 0) {
  //         const newOrder = [...componentOrder];
  //         [newOrder[index - 1], newOrder[index]] = [newOrder[index], newOrder[index - 1]];
  //         setComponentOrder(newOrder);
  //     }
  // };

  // const handleNext = (index) => {
  //     if (index < componentOrder.length - 1) {
  //         const newOrder = [...componentOrder];
  //         [newOrder[index + 1], newOrder[index]] = [newOrder[index], newOrder[index + 1]];
  //         setComponentOrder(newOrder);
  //     }
  // };

  const {
    ProjectDetailsData,
    setProjectDetailsData,
    highLevelRisksData,
    setHighLevelRisksData,
    riskManagementScheduleData,
    setRiskManagementScheduleData,
    personsInvolvedCardData,
    setPersonsInvolvedCardData,
    constructionPlantEquipmentData,
    setConstructionPlantEquipmentData,
    legislativeListData,
    setLegislativeListData,
    ppeCardData,
    setPPECardData,
    permitsData,
    setPermitsData,
    inductionSignOnData,
    setInductionSignOnData,
    peopleInvolvedData,
    setPeopleInvolvedData,
    formReviewerData,
    setFormReviewerData,
    riskTablesData,
    setRiskTablesData,
    saveFormData,
  } = useContext(FormContext);

  // const handleLogAllData = () => {
  //   console.log("Project Details:", ProjectDetailsData);
  //   console.log("High Level Risks:", highLevelRisksData);
  //   console.log("Risk Management Schedule:", riskManagementScheduleData);
  //   console.log("Persons Involved Card:", personsInvolvedCardData);
  //   console.log(
  //     "Construction Plant Equipment:",
  //     constructionPlantEquipmentData
  //   );
  //   console.log("Legislative List:", legislativeListData);
  //   console.log("PPE Card:", ppeCardData);
  //   console.log("Permits:", permitsData);
  //   console.log("Induction Sign On:", inductionSignOnData);
  //   console.log("People Involved:", peopleInvolvedData);
  //   console.log("Form Reviewer:", formReviewerData);
  //   console.log("Risk Tables:", riskTablesData);
  // };

  const handleSaveDocument = async (selectedColor) => {
    try {
      const documentData = {
        project_details: ProjectDetailsData,
        high_level_risks: {
          risks: highLevelRisksData.map(risk => ({
            ...risk,
            // Convert to integer for high level risks
            number: Number(risk.number) || risk.id + 1, // Use id + 1 as fallback
          })),
          color: selectedColor,
        },
        risk_management_schedule: {
          sections: riskManagementScheduleData.map(section => ({
            ...section,
            personsResponsible: section.personsResponsible || ""
          }))
        },
        persons_involved_card: {
          sections: personsInvolvedCardData.map((section) => ({
            ...section,
            rolesResponsibilities: Array.isArray(section.rolesResponsibilities)
              ? section.rolesResponsibilities
              : [section.rolesResponsibilities],
            qualificationsExperience: Array.isArray(section.qualificationsExperience)
              ? section.qualificationsExperience
              : [section.qualificationsExperience],
          })),
          color: selectedColor,
        },
        construction_plant_equipment: {
          sections: constructionPlantEquipmentData.map((section) => ({
            ...section,
            number: section.number?.toString() || "",
            plant_and_equipment: section.plant_and_equipment 
              ? [section.plant_and_equipment] // Convert string to single-item array
              : [],
            safety_consideration: Array.isArray(section.safety_consideration)
              ? section.safety_consideration
              : section.safety_consideration?.split('\n').filter(Boolean) || [],
            standards_and_regulations: Array.isArray(section.standards_and_regulations)
              ? section.standards_and_regulations
              : section.standards_and_regulations?.split('\n').filter(Boolean) || [],
          })),
        },
        legislative_list: {
          items: legislativeListData,
        },
        ppe_card: {
          ppeList: ppeCardData.map((item) => ({
            ...item,
            // Keep as string for PPE card
            number: item.number?.toString() || "",
          })),
        },
        permits: permitsData.map((permit) => ({
          ...permit,
          // Convert howToAcquire string to list by splitting on newlines
          howToAcquire: permit.howToAcquire 
            ? permit.howToAcquire.split('\n').filter(line => line.trim())
            : [],
          number: permit.number || "",
          permitName: permit.permitName || "",
          whyRequired: permit.whyRequired || "",
        })),
        induction_sign_on: inductionSignOnData,
        people_involved: peopleInvolvedData,
        form_reviewer: formReviewerData,
        risk_tables: riskTablesData,
        color: selectedColor || documentColor,
        documentLogo: icon,
        document_title: documentTitle,
      };

      // console.log('Document data before saving:', documentData); // Debug log
      await saveFormData(documentData);
      console.log('Document saved successfully');
    } catch (error) {
      console.error('Error saving document:', error);
    }
};

  const renderComponent = (componentName, index) => {
    switch (componentName) {
      case "ProjectDetails":
        return (
          <ProjectDetails
            key={componentName}
            formData={formData}
            handleInputChange={handleInputChange}
            // handleBlur={handleBlur}
            // loading={loading}  // Pass loading state to disable fields
            // handleFocus={handleFocus}
            color={documentColor}
          />
        );
      case "FormReviewer":
        return <FormReviewer key={componentName} color={documentColor} />;
      case "PeopleInvolved":
        return <PeopleInvolved key={componentName} color={documentColor} />;
      case "InductionSignOnAccordion":
        return <InductionSignOnAccordion key={componentName} color={documentColor} />;
      case "HighLevelRisksCard":
        return (
          <HighLevelRisksCard
            key={componentName}
            q1={q1}
            handleHighLevelRiskChange={handleHighLevelRiskChange}
            color={documentColor}
            // handlePrev={() => handlePrev(index)}
            // handleNext={() => handleNext(index)}
          />
        );
      case "RiskManagementScheduleCard":
        return (
          <RiskManagementScheduleCard
            key={componentName}
            formData={formData}
            handleRiskManagementChange={handleRiskManagementChange}
            color={documentColor}
            q2={q2}
            // handlePrev={() => handlePrev(index)}
            // handleNext={() => handleNext(index)}
          />
        );
      case "PersonsInvolvedCard":
        return (
          <PersonsInvolvedCard
            key={componentName}
            q3={q3}
            color={documentColor}
            // handlePrev={() => handlePrev(index)}
            // handleNext={() => handleNext(index)}
          />
        );
      case "ConstructionPlantEquipment":
        return (
          <ConstructionPlantEquipment
            key={componentName}
            formData={formData}
            handleInputChange={handleInputChange}
            color={documentColor}
            q7={q7}
            // handlePrev={() => handlePrev(index)}
            // handleNext={() => handleNext(index)}
          />
        );
      case "LegislativeAndAcops":
        return (
          <LegislativeAndAcops
            key={componentName}
            // formData={formData}
            // handleInputChange={handleInputChange}
            color={documentColor}
            q6={q6}
            // handlePrev={() => handlePrev(index)}
            // handleNext={() => handleNext(index)}
          />
        );
      case "PPECard":
        return (
          <PPECard
            key={componentName}
            // formData={formData}
            // handleInputChange={handleInputChange}
            color={documentColor}
            q4={q4}
            // handlePrev={() => handlePrev(index)}
            // handleNext={() => handleNext(index)}
          />
        );
      case "RequiredPermits":
        return (
          <RequiredPermits
            key={componentName}
            // formData={formData}
            // handleInputChange={handleInputChange}
            color={documentColor}
            q5={q5}
            // handlePrev={() => handlePrev(index)}
            // handleNext={() => handleNext(index)}
          />
        );
      case "RiskTablesCard":
        return (
          <RiskTablesCard
            key={componentName}
            // formData={formData}
            // handleInputChange={handleInputChange}
            color={documentColor}
            // handlePrev={() => handlePrev(index)}
            // handleNext={() => handleNext(index)}
          />
        );
      default:
        return null;
    }
  };

  const handleconsoleq1toq7 = () => {
    // console.log("Q1: ", q1);
    // console.log("Q2: ", q2);
    // console.log("Q3: ", q3);
    // console.log("Q4: ", q4);
    // console.log("Q5: ", q5);
    // console.log("Q6: ", q6);
    // console.log("Q7: ", q7);
  };

  const navigate = useNavigate();

  const handleBackClick = async () => {
    await handleSaveDocument();
    navigate("/user-dashboard/all-documents");
  };

  return (
    <Box>
      <Box sx={{ mr: 2 }}>
        <style>
          {`
          .hide-scrollbar {
            -ms-overflow-style: none;
            scrollbar-width: none;
          }
          .hide-scrollbar::-webkit-scrollbar {
            display: none;
          }
        `}
        </style>

        <Box
          className="hide-scrollbar"
          sx={{
            height: "100vh",
            overflowY: "scroll",
          }}
        >
          {/* <IconButton
            onClick={handleBackClick}
            sx={{
              position: "relative",
              top: 10,
              left: 10,
              backgroundColor: "white",
              "&:hover": {
                backgroundColor: "lightgrey",
              },
            }}
          >
            <ArrowBackIcon />
          </IconButton> */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: documentColor,
              p: 2,
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              border: `2px solid ${documentColor}`,
              mb: 2,
              minHeight: "160px",
              mt: 2,
              mx: 2,
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 10,
                right: openColorDialog ? 25 : 10,
                cursor: "pointer",
              }}
            >
              <IconButton onClick={handleOpenColorDialog}>
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    backgroundColor: documentColor,
                    border: "2px solid #DDDDDD",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      "& .MuiSvgIcon-root": {
                        opacity: 1,
                      },
                    },
                  }}
                >
                  <HistoryEduIcon
                    sx={{
                      color: "black",
                      opacity: 0,
                    }}
                  />
                </Box>
              </IconButton>
              <ColorPickerAndApiModal
                open={openColorDialog}
                color={documentColor}
                onColorChange={handleColorChange}
                onClose={handleCloseColorDialog}
                setQ1={setQ1}
                setQ2={setQ2}
                setQ3={setQ3}
                setQ4={setQ4}
                setQ5={setQ5}
                setQ6={setQ6}
                setQ7={setQ7}
                loading={loading}
                setLoading={setLoading}
                setPercentage={setPercentage}
                handleSaveDocument={handleSaveDocument} // Pass the function here
              />
            </Box>

            <Box sx={{ display: "flex" }}>
              {/* Left Section with Uploadable Icon */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRight: `2px solid ${documentColor}`,
                  pr: 2,
                  height: "160px",
                  minWidth: "200px",
                  width: "35%",
                  overflow: "hidden",
                  cursor: "pointer",
                  background: "white",
                  borderRadius: "5px",
                  position: "relative",
                }}
              >
                <input
                  accept="image/*"
                  type="file"
                  id="icon-upload"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const maxSize = 1024 * 1024; // 1MB in bytes
                    
                    if (file && file.size > maxSize) {
                      alert('File size must be less than 1MB');
                      e.target.value = ''; // Reset the input
                      return;
                    }
                    
                    handleIconChange(e);
                  }}
                />
                <label htmlFor="icon-upload">
                  <IconButton component="span">
                    <img
                      src={logo}
                      alt="Icon"
                      style={{
                        height: "135px",
                        width: "auto",
                        maxWidth: "100%",
                        objectFit: "contain",
                        borderRadius: "5px",
                      }}
                    />
                  </IconButton>
                </label>
              </Box>

              {/* Right Section with Title */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  ml: 2,
                  flexGrow: 1,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Roboto, sans-serif",
                    color: "#000",
                  }}
                >
                  SAFE WORK METHOD STATEMENT
                </Typography>

                <TextField
                  variant="outlined"
                  placeholder="Enter document title"
                  fullWidth
                  value={documentTitle}
                  onChange={(e) => setDocumentTitle(e.target.value)}
                  sx={{
                    mt: 1,
                    backgroundColor: "#fff",
                    fontFamily: "Roboto, sans-serif",
                    borderRadius: "5px",
                  }}
                  InputProps={{
                    sx: {
                      height: "40px",
                    },
                  }}
                />
                {loading && (
                  <Box sx={{ width: "100%", mt: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ flexGrow: 1, mr: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={(percentage / 7) * 100}
                          sx={{
                            borderRadius: "25px",
                            border: "2px solid #665A48",
                            height: "20px",
                            bgcolor: darken(documentColor, 0.1),
                            boxShadow: "inset 0 2px 5px rgba(0, 0, 0, 0.15)",
                            "& .MuiLinearProgress-bar": {
                              borderRadius: "25px",
                              backgroundColor: lighten(documentColor, 0.2),
                              boxShadow: 5,
                              borderRight: "2px solid #665A48",
                            },
                          }}
                        />
                      </Box>
                      <Typography variant="body1">{percentage}/7</Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, display: "flex", alignItems: "center" }}
                    >
                      <Box
                        component="img"
                        src={bot}
                        alt="Bot Image"
                        sx={{
                          height: 25,
                          width: 25,
                          mr: 1,
                          mb: 1,
                          animation: "tilt 1s infinite ease-in-out",
                        }}
                      />
                      <Box component="style">
                        {`
                      @keyframes tilt {
                          0% { transform: rotate(0deg); }
                          25% { transform: rotate(10deg); }
                          50% { transform: rotate(0deg); }
                          75% { transform: rotate(-10deg); }
                          100% { transform: rotate(0deg); }
                      }
                      `}
                      </Box>
                      {waitingMessages[messageIndex]}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          {/* Render components based on their order */}
          {componentOrder.map((componentName, index) => (
            <Box
              key={componentName}
              sx={{ mb: 2, display: "flex", alignItems: "center", mr: 2 }}
              draggable
              onDragStart={(e) => handleDragStart(index, e)}
              onDragEnd={handleDragEnd}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(index, e)}
            >
              <IconButton
                sx={{
                  cursor: "grab",
                  "&:hover": { backgroundColor: "transparent" },
                  "&:active": {
                    cursor: "grabbing",
                    transform: "scale(0.95)",
                  },
                }}
              >
                <DragIndicator />
              </IconButton>

              <Box sx={{ width: "100%" }}>
                {renderComponent(componentName, index)}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <CopyrightContainer contractorName={formData.contractorName} />
    </Box>
  );
};

export default LandingPage;
