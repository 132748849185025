import { useState, useCallback, memo } from 'react'
import * as RadioGroup from '@radix-ui/react-radio-group'
import * as Label from '@radix-ui/react-label'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { useUser } from '../context/UserContext'
import axios from 'axios'

// Initial form state - moved outside component to prevent recreation
const initialFormState = {
  easeOfUse: '', difficulties: '', overallFunctionality: '', difficultFeaturesToUnderstand: '',
  accuracy: '', expectations: '', industryStandards: '', relevance: '',
  mostUsedFeatures: '', usefulOrLackingFeatures: '', aiPerformance: '', missingFeatures: '',
  bugs: '', unexpectedBehaviors: '', replication: '',
  overallDesign: '', userInterface: '', designSuggestions: '', confusingAreas: '',
  futureValue: '', additionalDocuments: '', additionalFeatures: '', futureVision: '', finalComments: '',
  additionalComments: ''
}

// Memoized TextArea component
const TextArea = memo(({ id, name, value, onChange, placeholder }) => (
  <textarea 
    id={id} 
    name={name} 
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    className="w-full min-h-[100px] p-2 rounded bg-gray-800 text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
  />
))

// Memoized RatingScale component
const RatingScale = memo(({ name, question, value, onChange }) => (
  <div className="space-y-2">
    <Label.Root className="text-white">{question}</Label.Root>
    <RadioGroup.Root 
      name={name} 
      value={value}
      onValueChange={(value) => onChange(name, value)}
      className="flex flex-col gap-2"
    >
      {[1, 2, 3, 4, 5].map((value) => (
        <div key={value} className="flex items-center space-x-2">
          <RadioGroup.Item 
            value={value.toString()} 
            id={`${name}-${value}`}
            className="w-4 h-4 rounded-full border border-white hover:bg-blue-500/50 data-[state=checked]:bg-blue-500"
          >
            <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-2 after:h-2 after:rounded-full after:bg-white" />
          </RadioGroup.Item>
          <Label.Root htmlFor={`${name}-${value}`} className="text-white">{value}</Label.Root>
        </div>
      ))}
    </RadioGroup.Root>
  </div>
))

// Memoized Section component
const Section = memo(({ title, children, index, isOpen, onToggle }) => (
  <motion.div
    initial={false}
    animate={{ backgroundColor: isOpen ? "rgba(59, 130, 246, 0.1)" : "rgba(30, 41, 59, 0.4)" }}
    className="mb-4 rounded-lg overflow-hidden"
  >
    <motion.button
      type="button"
      onClick={() => onToggle(index)}
      className="w-full p-4 flex justify-between items-center text-left font-semibold text-white"
      whileHover={{ backgroundColor: "rgba(59, 130, 246, 0.2)" }}
    >
      {title}
      {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
    </motion.button>
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="p-4 text-sm text-white">{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  </motion.div>
))

export default function Feedback() {
  const { user } = useUser()
  const [formData, setFormData] = useState(initialFormState)
  const [openSection, setOpenSection] = useState(null)

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }, [])

  const handleRatingChange = useCallback((name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }))
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const isConfirmed = window.confirm('Are you sure you want to submit the feedback?');
    if (!isConfirmed) return;

    try {
      const response = await axios.post(
        'https://www.icromm.com/api/submit-feedback',
        {
          userInfo: {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone: user.phone
          },
          feedback: formData
        }
      )

      if (response.data.message) {
        alert('Feedback submitted successfully!')
        setFormData(initialFormState)
        setOpenSection(null)
      }
    } catch (error) {
      console.error('Error submitting feedback:', error)
      alert('Failed to submit feedback. Please try again.')
    }
  }

  const toggleSection = useCallback((index) => {
    setOpenSection(prev => prev === index ? null : index)
  }, [])

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-blue-900 text-white p-8 mx-[-27px] my-[-27px]">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-4xl mx-auto"
      >
        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="flex items-center gap-4 mb-8">
            <img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/icrommLogoWhiteTop-YoKxADhidUCxm0p8hfxDqV1OMy6qVP.png"
              alt="Icromm Logo"
              className="w-16 h-auto"
            />
            <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
              ICROMM Beta Testing Feedback Questionnaire
            </h1>
          </div>
          <p className="text-xl mb-8">
            Thank you for participating in the beta testing of ICROMM. Your feedback is crucial in helping us improve. Please answer the following questions to provide insights into your experience.
          </p>
          <p className="bg-blue-900 border-l-4 border-blue-500 text-blue-100 p-4 my-4 rounded">
            The questionnaire uses a rating scale where 1 represents the highest rating and 5 represents the lowest rating.
          </p>

          <Section title="1. General Feedback" index={0} isOpen={openSection === 0} onToggle={toggleSection}>
            <div className="space-y-6">
              <RatingScale name="easeOfUse" question="How easy was it to get started with ICROMM?" value={formData.easeOfUse} onChange={handleRatingChange} />
              
              <div className="space-y-2">
                <Label.Root htmlFor="difficulties" className="text-white">
                  Did you encounter any difficulties using the software?
                </Label.Root>
                <TextArea id="difficulties" name="difficulties" value={formData.difficulties} onChange={handleInputChange} placeholder="Please describe any issues or obstacles you faced." />
              </div>

              <RatingScale name="overallFunctionality" question="How would you rate the overall functionality of the software?" value={formData.overallFunctionality} onChange={handleRatingChange} />

              <div className="space-y-2">
                <Label.Root htmlFor="difficultFeaturesToUnderstand" className="text-white">
                  Were there any features that were difficult to understand or use?
                </Label.Root>
                <TextArea id="difficultFeaturesToUnderstand" name="difficultFeaturesToUnderstand" value={formData.difficultFeaturesToUnderstand} onChange={handleInputChange} placeholder="Please specify which features and why." />
              </div>
            </div>
          </Section>

          <Section title="2. Outputs & Accuracy" index={1} isOpen={openSection === 1} onToggle={toggleSection}>
            <div className="space-y-6">
              <RatingScale name="accuracy" question="How accurate were the generated Safe Work Method Statements (SWMS) and Risk Assessments?" value={formData.accuracy} onChange={handleRatingChange} />

              <div className="space-y-2">
                <Label.Root htmlFor="expectations" className="text-white">
                  Did the generated documents meet your expectations based on the input data you provided?
                </Label.Root>
                <TextArea id="expectations" name="expectations" value={formData.expectations} onChange={handleInputChange} placeholder="Please describe any discrepancies or issues you encountered." />
              </div>

              <div className="space-y-2">
                <Label.Root htmlFor="industryStandards" className="text-white">
                  Were the outputs aligned with industry standards and requirements?
                </Label.Root>
                <TextArea id="industryStandards" name="industryStandards" value={formData.industryStandards} onChange={handleInputChange} placeholder="Please provide any examples where the outputs met or didn't meet these standards." />
              </div>

              <div className="space-y-2">
                <Label.Root htmlFor="relevance" className="text-white">
                  How relevant were the outputs to your specific site or business needs?
                </Label.Root>
                <TextArea id="relevance" name="relevance" value={formData.relevance} onChange={handleInputChange} placeholder="Please describe how well the software met your particular requirements." />
              </div>
            </div>
          </Section>

          <Section title="3. Feature Testing" index={2} isOpen={openSection === 2} onToggle={toggleSection}>
            <div className="space-y-6">
              <div className="space-y-2">
                <Label.Root htmlFor="mostUsedFeatures" className="text-white">
                  Which features of ICROMM did you use the most, and how well did they perform?
                </Label.Root>
                <TextArea id="mostUsedFeatures" name="mostUsedFeatures" value={formData.mostUsedFeatures} onChange={handleInputChange} placeholder="Please list the features and provide feedback." />
              </div>

              <div className="space-y-2">
                <Label.Root htmlFor="usefulOrLackingFeatures" className="text-white">
                  Were there any specific features that were particularly useful or lacking?
                </Label.Root>
                <TextArea id="usefulOrLackingFeatures" name="usefulOrLackingFeatures" value={formData.usefulOrLackingFeatures} onChange={handleInputChange} placeholder="Please specify the feature and provide suggestions for improvement." />
              </div>

              <RatingScale name="aiPerformance" question="How well did the AI-driven features perform in generating the required documents?" value={formData.aiPerformance} onChange={handleRatingChange} />

              <div className="space-y-2">
                <Label.Root htmlFor="missingFeatures" className="text-white">
                  Were there any features you expected to be included but were missing?
                </Label.Root>
                <TextArea id="missingFeatures" name="missingFeatures" value={formData.missingFeatures} onChange={handleInputChange} placeholder="Please list any missing features and explain why they would be useful." />
              </div>
            </div>
          </Section>

          <Section title="4. Bug Reporting" index={3} isOpen={openSection === 3} onToggle={toggleSection}>
            <div className="space-y-6">
              <div className="space-y-2">
                <Label.Root htmlFor="bugs" className="text-white">
                  Did you encounter any bugs, crashes, or errors during your testing?
                </Label.Root>
                <TextArea id="bugs" name="bugs" value={formData.bugs} onChange={handleInputChange} placeholder="Please describe the issue(s), including what you were doing when the problem occurred." />
              </div>

              <div className="space-y-2">
                <Label.Root htmlFor="unexpectedBehaviors" className="text-white">
                  Were there any unexpected behaviors from the software that impacted your use?
                </Label.Root>
                <TextArea id="unexpectedBehaviors" name="unexpectedBehaviors" value={formData.unexpectedBehaviors} onChange={handleInputChange} placeholder="Please provide examples of how the software behaved unexpectedly." />
              </div>

              <div className="space-y-2">
                <Label.Root htmlFor="replication" className="text-white">
                  Can you replicate the issues you encountered?
                </Label.Root>
                <TextArea id="replication" name="replication" value={formData.replication} onChange={handleInputChange} placeholder="Please describe any steps that lead to the bug or issue so we can replicate it on our end." />
              </div>
            </div>
          </Section>

          <Section title="5. User Experience" index={4} isOpen={openSection === 4} onToggle={toggleSection}>
            <div className="space-y-6">
              <RatingScale name="overallDesign" question="How would you rate the overall design and interface of ICROMM?" value={formData.overallDesign} onChange={handleRatingChange} />

              <div className="space-y-2">
                <Label.Root htmlFor="userInterface" className="text-white">
                  Was the user interface intuitive and easy to navigate?
                </Label.Root>
                <TextArea id="userInterface" name="userInterface" value={formData.userInterface} onChange={handleInputChange} placeholder="Please provide feedback on navigation and ease of use." />
              </div>

              <div className="space-y-2">
                <Label.Root htmlFor="designSuggestions" className="text-white">
                  Do you have any suggestions for improving the design or user interface?
                </Label.Root>
                <TextArea id="designSuggestions" name="designSuggestions" value={formData.designSuggestions} onChange={handleInputChange} placeholder="Please provide detailed suggestions or ideas for improvement." />
              </div>

              <div className="space-y-2">
                <Label.Root htmlFor="confusingAreas" className="text-white">
                  Did you encounter any areas of the interface that were confusing or unclear?
                </Label.Root>
                <TextArea id="confusingAreas" name="confusingAreas" value={formData.confusingAreas} onChange={handleInputChange} placeholder="Please describe these areas and suggest improvements if any." />
              </div>
            </div>
          </Section>

          <Section title="6. Future Development" index={5} isOpen={openSection === 5} onToggle={toggleSection}>
            <div className="space-y-6">
              <div className="space-y-2">
                <Label.Root htmlFor="futureValue" className="text-white">
                  Where do you believe ICROMM could focus its attention to add more value to your business?
                </Label.Root>
                <TextArea id="futureValue" name="futureValue" value={formData.futureValue} onChange={handleInputChange} placeholder="Please provide suggestions for how ICROMM could improve its overall value to your workflow, safety management, or business operations." />
              </div>

              <div className="space-y-2">
                <Label.Root htmlFor="additionalDocuments" className="text-white">
                  Are there any specific documents or types of documentation that you believe ICROMM could be adapted to?
                </Label.Root>
                <TextArea id="additionalDocuments" name="additionalDocuments" value={formData.additionalDocuments} onChange={handleInputChange} placeholder="Please specify any documents that ICROMM does not currently generate but would be valuable to your business." />
              </div>

              <div className="space-y-2">
                <Label.Root htmlFor="additionalFeatures" className="text-white">
                  What additional features would you like to see in ICROMM to further enhance its functionality?
                </Label.Root>
                <TextArea id="additionalFeatures" name="additionalFeatures" value={formData.additionalFeatures} onChange={handleInputChange} placeholder="Please list any new features, tools, or integrations that would make ICROMM more useful to your business." />
              </div>

              <div className="space-y-2">
                <Label.Root htmlFor="futureVision" className="text-white">
                  How do you envision ICROMM evolving to meet future industry trends or needs?
                </Label.Root>
                <TextArea id="futureVision" name="futureVision" value={formData.futureVision} onChange={handleInputChange} placeholder="Please provide suggestions for how ICROMM could improve its overall value to your workflow, safety management, or business operations." />
              </div>

              <div className="space-y-2">
                <Label.Root htmlFor="additionalComments" className="text-white">
                  Are there any other comments or suggestions for the future development of ICROMM?
                </Label.Root>
                <TextArea id="additionalComments" name="additionalComments" value={formData.additionalComments} onChange={handleInputChange} placeholder="Any general comments or further suggestions for improvement." />
              </div>
            </div>
          </Section>

          <Section title="Additional Comments" index={6} isOpen={openSection === 6} onToggle={toggleSection}>
            <div className="space-y-6">
              <div className="space-y-2">
                <Label.Root htmlFor="finalComments" className="text-white">
                  Is there anything else you'd like to share regarding your experience with ICROMM?
                </Label.Root>
                <TextArea id="finalComments" name="finalComments" value={formData.finalComments} onChange={handleInputChange} placeholder="Any general comments or further suggestions for improvement." />
              </div>
            </div>
          </Section>

          <button 
            type="submit" 
            className="px-8 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-full transition-colors"
          >
            Submit Feedback
          </button>
        </form>
      </motion.div>
    </div>
  )
}