'use client'

import * as ScrollArea from '@radix-ui/react-scroll-area'
import * as Accordion from '@radix-ui/react-accordion'
import { ChevronDown } from 'lucide-react'

export default function AgreementAndPrivacyPolicy() {
  return (
    <div className="min-h-screen py-8 px-4 sm:px-6 lg:px-8 bg-white">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">
        ICROMM Beta Software Testing Agreement and Privacy Policy
      </h1>
      
      <Accordion.Root type="single" collapsible className="w-full">
        <Accordion.Item value="agreement" className="mb-4">
          <Accordion.Header>
            <Accordion.Trigger className="group flex w-full justify-between items-center text-xl font-semibold py-4 border-b border-gray-200">
              Beta Software Testing Agreement
              <ChevronDown className="h-6 w-6 shrink-0 transition-transform duration-200 group-data-[state=open]:rotate-180" />
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className="overflow-hidden data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp">
            <div className="space-y-4 text-sm">
              <p className="text-sm text-gray-600 mb-4">
                Effective Date: 18th November 2024
                <br />
                Version: 1.01
              </p>
              <p>
                This Beta Software Testing Agreement ("Agreement") is entered into by and between ICROMM Pty Ltd, an Australian company registered under the laws of Queensland, ("Company", "we", "us", or "our") and you, the beta tester ("you" or "the Tester"). By accepting and using the Beta Software, you agree to be bound by the terms and conditions set forth in this Agreement.
              </p>
              
              <h3 className="text-lg font-semibold mt-4">1. Grant of Access</h3>
              <p>
                We hereby grant you a limited, non-exclusive, non-transferable license to access and use the Beta Software solely for the purposes of evaluation, testing, and providing feedback. This grant is subject to the terms and conditions of this Agreement, and is for internal testing purposes only, not for any commercial or production use.
              </p>

              <h3 className="text-lg font-semibold mt-4">2. Use of Beta Software</h3>
              <p>
                The Beta Software is provided "as is" and may contain errors, bugs, or other defects that could impact its performance. You agree to use the Beta Software only in the manner described in this Agreement and not to:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>Modify, distribute, or sublicense the Beta Software to any third party.</li>
                <li>Use the Beta Software for any purposes outside of the scope of testing or feedback.</li>
                <li>Reverse engineer, decompile, or disassemble the Beta Software, except to the extent permitted by applicable law.</li>
              </ul>
              <p>You are solely responsible for ensuring that your use of the Beta Software and its outputs complies with applicable laws and regulations.</p>

              <h3 className="text-lg font-semibold mt-4">3. Confidentiality</h3>
              <p>
                You agree to keep the Beta Software, any associated documentation, and any information related to the Beta Software confidential. You will not disclose, share, or make available any information about the Beta Software, including, but not limited to, its features, functionality, or performance, to any third party without prior written consent from us. This confidentiality obligation will survive the termination of this Agreement.
              </p>

              <h3 className="text-lg font-semibold mt-4">4. Feedback and Data Collection</h3>
              <p>
                As a Beta Tester, you are encouraged to provide feedback, bug reports, suggestions for improvements, and any other information that can help improve the Beta Software ("Feedback"). You agree to provide accurate, honest, and timely feedback during the testing period.
              </p>
              <p>
                In addition to Feedback, we may collect technical information regarding your use of the Beta Software, such as system logs, performance data, and error reports, to improve the software. You agree that any such data, including Feedback, is the property of ICROMM Pty Ltd and can be used, modified, and incorporated into the Beta Software without compensation or further approval.
              </p>

              <h3 className="text-lg font-semibold mt-4">5. Risks and Limitations</h3>
              <p>
                You acknowledge and accept the risks inherent in using the Beta Software, including the possibility of software defects, interruptions, or errors that could impact your system or data. The Beta Software is not intended for use in production or mission-critical systems. You agree to use the Beta Software at your own risk and to take appropriate precautions, such as backing up any data you may input into the system.
              </p>
              <p>
                We do not guarantee that the Beta Software will meet your requirements or that its operation will be uninterrupted, secure, or error-free. You understand that the Beta Software is still under development and may undergo significant changes.
              </p>

              <h3 className="text-lg font-semibold mt-4">6. Termination of Access</h3>
              <p>
                We may, at our sole discretion, terminate or suspend your access to the Beta Software at any time, for any reason, with or without notice. Upon termination, you must immediately cease using the Beta Software, delete any copies you have, and, if applicable, return or destroy any associated documentation.
              </p>
              <p>
                You may also terminate your participation in the Beta Test at any time by ceasing to use the Beta Software. Termination of this Agreement does not relieve you of your confidentiality obligations or any other responsibilities set forth in this Agreement.
              </p>

              <h3 className="text-lg font-semibold mt-4">7. No Warranty</h3>
              <p>
                The Beta Software is provided "as is" and "as available," with all faults, and ICROMM Pty Ltd makes no representations or warranties, express or implied, regarding the Beta Software or any outputs generated by the software, including but not limited to the following:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>
                  <strong>Accuracy and Reliability of Outputs:</strong> The Beta Software generates outputs, such as Safe Work Method Statements (SWMS), Risk Assessments, and other documentation ("Outputs"), based on both the data you input and the artificial intelligence (AI) algorithms that process that data. While the AI is designed to automate and streamline the documentation process, the quality and accuracy of the Outputs depend heavily on the quality, accuracy, and completeness of the inputs provided by you. ICROMM Pty Ltd does not guarantee the accuracy, reliability, or completeness of the Outputs. Errors, omissions, or discrepancies in the inputs you provide may result in inaccuracies or deficiencies in the Outputs. Therefore, it is your responsibility to ensure that the data you enter into the Beta Software is accurate, complete, and up-to-date.
                </li>
                <li>
                  <strong>AI-Generated Outputs:</strong> The Beta Software utilizes AI to interpret and process the input data, including applying industry standards, codes of practice, and safety guidelines to generate the Outputs. However, the AI is not infallible and may not always interpret the data or apply these standards perfectly. As such, ICROMM Pty Ltd does not warrant that the Outputs generated by the AI will always align with your specific needs, business requirements, or local regulations. The AI is constantly being improved, and during the Beta phase, the AI's performance and decision-making capabilities may not be fully optimized.
                </li>
                <li>
                  <strong>Fitness for Intended Purpose:</strong> The Beta Software and its Outputs are not warranted to be suitable or fit for any particular purpose. The software is still under development, and the generated Outputs may not meet the precise specifications or requirements of every user or project. While the AI is designed to produce accurate and relevant documentation based on the provided data, there may still be limitations, errors, or defects in how the AI processes the information. You should not rely solely on the Beta Software for critical or final documentation without independently reviewing and validating the Outputs.
                </li>
                <li>
                  <strong>Functionality and Performance:</strong> ICROMM Pty Ltd does not guarantee that the Beta Software will operate without error, interruption, or faults. The software may experience technical glitches, bugs, or AI-related performance issues that could affect the quality, accuracy, or reliability of the Outputs. The Beta Software is being tested and refined, and you acknowledge that the outputs produced may vary in quality and performance during the testing phase. It is recommended that you do not use the Outputs for operational purposes until you have validated them.
                </li>
                <li>
                  <strong>Data Integrity and Input:</strong> The accuracy of the Outputs is contingent upon the quality of the data you input into the Beta Software. The AI processes the data you provide, and any inaccuracies, inconsistencies, or incomplete information in your inputs may lead to incorrect or incomplete Outputs. ICROMM Pty Ltd is not responsible for any discrepancies in the Outputs caused by inaccurate or incomplete data entered by you. It is your responsibility to ensure that any data provided to the Beta Software is accurate, complete, and suitable for your intended use.
                </li>
                <li>
                  <strong>Recommendation for Review and Professional Verification:</strong> Given that the Beta Software relies on both user inputs and AI algorithms, we strongly recommend that you perform your own review of all Outputs generated by the software before using them for any operational, safety-critical, or legal purposes. The AI may not always account for site-specific nuances or local regulatory variations. To ensure the Outputs meet necessary safety standards, business requirements, and legal compliance, we suggest you engage qualified professionals, such as safety officers, engineers, or construction experts, to review and verify the generated documentation. This review will help ensure the Outputs are appropriate for your specific circumstances.
                </li>
                <li>
                  <strong>Limitation of Liability for Outputs:</strong> ICROMM Pty Ltd disclaims any responsibility for any loss, damage, or injury arising directly or indirectly from the use of the Beta Software, including the use of Outputs generated by the software. This includes, without limitation, any loss of data, business disruption, legal non-compliance, or safety incidents resulting from reliance on the Outputs. The Beta Software and the AI-generated Outputs are not intended to replace professional judgment or the advice of qualified experts. You are solely responsible for ensuring that the Outputs comply with relevant laws, regulations, and standards.
                </li>
                <li>
                  <strong>No Guarantee of Future Performance:</strong> The Beta Software, including the AI components, is still under development and may undergo significant changes in functionality and performance. The Outputs generated during the Beta testing phase may not reflect the quality or accuracy of the final product once the software is commercially released. The AI's decision-making capabilities may also evolve, and future updates may improve or modify how the AI processes inputs and generates Outputs.
                </li>
              </ul>
              <p>
                By using the Beta Software, you acknowledge that you are assuming all risks associated with its use, including the risk that the software, its AI components, and the generated Outputs may not be fully functional, accurate, or suitable for your intended purposes. You agree to independently verify the Outputs and ensure they meet the required standards and compliance requirements for your business or construction projects. If necessary, you will engage relevant safety, legal, or construction professionals to confirm the suitability and compliance of the generated Outputs.
              </p>
              <p>
                By using the Beta Software, you expressly acknowledge that you are assuming all risks associated with the use of the software, including the risk that the software, its AI components and the generated Outputs may not be fully functional, accurate, or appropriate for your intended purposes. You agree to independently verify the Outputs and ensure they meet the necessary standards and requirements for your business or construction projects. If necessary, you will engage relevant safety, legal, or construction professionals to confirm the suitability and compliance of the generated Outputs. ICROMM Pty Ltd disclaims all warranties, representations, or guarantees with respect to the Beta Software and its Outputs to the maximum extent permitted by applicable law. You understand that the Beta Software is still being tested and refined, and the Outputs generated during the testing phase may not reflect the final product's performance or accuracy.
              </p>

              <h3 className="text-lg font-semibold mt-4">8. Ownership and Intellectual Property</h3>
              <p>
                All intellectual property rights, including copyrights, trademarks, patents, and any other proprietary rights in the Beta Software, are owned by ICROMM Pty Ltd and its licensors. You acknowledge that you have no rights to the Beta Software other than the limited license granted in this Agreement. The Beta Software, including any updates, enhancements, or modifications, remains the exclusive property of ICROMM Pty Ltd.
              </p>

              <h3 className="text-lg font-semibold mt-4">9. Use of AI Language Models</h3>
              <p>
                You acknowledge and agree that the information you provide while using the Beta Software may be processed by or transmitted to external AI language models, third-party cloud-based services, or other external systems for the purpose of improving the software, generating outputs, or performing related tasks such as data processing, analysis, and learning. This may include, but is not limited to, the use of AI to automate the creation of Safe Work Method Statements (SWMS), Risk Assessments, or other documentation based on the data you input into the system.
              </p>
              <p>
                While ICROMM Pty Ltd strives to take reasonable measures to protect the security, confidentiality, and privacy of any data you provide, you acknowledge that the transmission of your data to these third-party services or external AI systems carries inherent risks. By inputting data into the Beta Software, you consent to the processing and possible storage of such data by third-party services, which may be located outside your direct control. These third-party systems may process and store data in jurisdictions that may not provide the same level of privacy protections as Australian law.
              </p>
              <h4 className="text-md font-semibold mt-2">Recommendations Regarding Sensitive and Confidential Information</h4>
              <p>
                In line with the Privacy Act 1988 (Cth) and the OAIC's guidance on privacy and AI, ICROMM Pty Ltd strongly recommends that you avoid inputting any confidential, sensitive, or personally identifiable information (PII) into the Beta Software. This includes, but is not limited to, personal data, proprietary business information, financial data, sensitive health information, or any other information subject to confidentiality agreements, privacy regulations, or industry-specific data protection laws.
              </p>
              <p>
                You acknowledge that ICROMM Pty Ltd cannot guarantee the same level of data protection or confidentiality once data is transmitted to external AI systems or third-party services. While reasonable efforts are made to protect your data, the very nature of using external AI models introduces additional privacy risks. These risks may include, but are not limited to, unauthorized access, data leakage, misuse of information, or the potential for AI models to learn from and retain sensitive data without explicit consent or proper safeguards.
              </p>
              <h4 className="text-md font-semibold mt-2">Data Security and User Responsibility</h4>
              <p>
                You understand and agree that any data you input into the Beta Software is processed and transmitted at your own risk, and that ICROMM Pty Ltd is not liable for any unauthorized access, misuse, loss, or corruption of your data that may result from its transmission, processing, or storage by third-party services. This includes, but is not limited to, risks of data exposure, data breaches, or inadvertent data sharing. You are solely responsible for ensuring that any data provided to the Beta Software is compliant with applicable privacy laws, including the Privacy Act 1988 (Cth) and any other relevant regulations governing data protection.
              </p>
              <p>
                Furthermore, ICROMM Pty Ltd does not guarantee that any data provided to the Beta Software will remain confidential or protected once it is transmitted to third-party AI models or cloud systems for processing. The AI models used by the software may learn from and use input data to generate outputs and may be stored or retained in remote servers. This means that your data could be exposed to risks associated with the infrastructure and security practices of the external systems involved.
              </p>
              <h4 className="text-md font-semibold mt-2">Personal Data and Privacy Compliance</h4>
              <p>
                You agree to comply with all relevant privacy laws, including the Australian Privacy Principles (APPs) outlined in the Privacy Act 1988 (Cth), when using the Beta Software. This includes taking steps to ensure that any personal information or sensitive data entered into the Beta Software does not breach privacy protections, confidentiality agreements, or data-sharing regulations. ICROMM Pty Ltd is not responsible for any violations of privacy laws or any non-compliance with confidentiality obligations arising from your use of the Beta Software.
              </p>
              <p>
                To mitigate the privacy risks outlined above, ICROMM Pty Ltd recommends that you limit the input of personal, sensitive, or confidential data to the Beta Software, especially where such data is subject to external privacy obligations. If you choose to input sensitive or confidential data into the Beta Software, you do so at your own risk, and you agree to hold ICROMM Pty Ltd harmless from any liabilities arising from the transmission, processing, or storage of such data.
              </p>

              <h3 className="text-lg font-semibold mt-4">10. User Account and Security</h3>
              <p>
                You are solely responsible for maintaining the confidentiality of your login details (username and password). You agree not to share your login credentials with any third party, and access to the Beta Software is intended solely for the individual who owns the email login associated with the account. You agree not to share, distribute, or allow any other person to use your account or login details. Any unauthorized access or use of the Beta Software due to the sharing of your login credentials is your responsibility, and you shall be liable for any activity conducted through your account.
              </p>
              <p>
                If ICROMM suspects that your username or account credentials are being used by more than one individual, it reserves the right to immediately suspend or terminate access to your account without notice. You must promptly notify ICROMM Pty Ltd if you suspect any unauthorized use of your account, and you should take immediate steps to secure your account, including updating your login credentials.
              </p>

              <h3 className="text-lg font-semibold mt-4">11. Indemnity</h3>
              <p>
                You agree to indemnify and hold harmless ICROMM Pty Ltd, its affiliates, officers, directors, employees, agents, and contractors from and against any and all claims, damages, liabilities, costs, or expenses (including legal fees) arising out of or in connection with:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>Your use or misuse of the Beta Software.</li>
                <li>Your breach of any of the terms and conditions of this Agreement.</li>
                <li>Any claims made by third parties arising out of your use of the Beta Software.</li>
              </ul>

              <h3 className="text-lg font-semibold mt-4">12. Limitation of Liability</h3>
              <p>
                To the maximum extent permitted by law, ICROMM Pty Ltd will not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from your use or inability to use the Beta Software, even if we have been advised of the possibility of such damages. This includes, but is not limited to, loss of data, loss of profits, business interruption, or any other loss arising from your use of the Beta Software.
              </p>

              <h3 className="text-lg font-semibold mt-4">13. Governing Law</h3>
              <p>
                This Agreement is governed by the laws of the State of Queensland, Australia, without regard to its conflict of laws principles. You agree to submit to the exclusive jurisdiction of the courts located in Queensland, Australia, for the resolution of any disputes arising out of or related to this Agreement.
              </p>

              <h3 className="text-lg font-semibold mt-4">14. Dispute Resolution</h3>
              <p>
                If any dispute arises under this Agreement, the parties agree to attempt to resolve the dispute through informal negotiations. If the dispute cannot be resolved through informal negotiations, the parties agree to submit the dispute to mediation before pursuing any legal action. The mediation shall be conducted in Gold Coast, Australia, under the rules of a law firm of ICROMM's choosing.
              </p>

              <h3 className="text-lg font-semibold mt-4">15. Amendments</h3>
              <p>
                We reserve the right to modify or update the terms of this Agreement at any time. Any amendments will be posted on our website or provided to you directly, and your continued use of the Beta Software after such amendments shall constitute your acceptance of the updated terms.
              </p>

              <h3 className="text-lg font-semibold mt-4">16. Entire Agreement</h3>
              <p>
                This Agreement constitutes the entire understanding between you and ICROMM Pty Ltd with respect to the Beta Software, and supersedes any prior agreements or understandings, whether written or oral, relating to the subject matter hereof. If any provision of this Agreement is deemed invalid or unenforceable by a court of competent jurisdiction, the remainder of the Agreement will remain in full force and effect.
              </p>

              <p className="font-semibold mt-4">
                By accepting and using the Beta Software, you acknowledge that you have read, understood, and agreed to the terms and conditions outlined in this Beta Software Testing Agreement.
              </p>
            </div>
          </Accordion.Content>
        </Accordion.Item>

        <Accordion.Item value="privacy">
          <Accordion.Header>
            <Accordion.Trigger 
              className="group flex w-full justify-between items-center text-xl font-semibold py-4 border-b border-gray-200"
              onClick={() => {
                // Add a small delay to ensure the content is rendered before scrolling
                setTimeout(() => {
                  document.querySelector('[data-state="open"]')?.scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'start'
                  });
                }, 100);
              }}
            >
              Privacy Policy
              <ChevronDown className="h-6 w-6 shrink-0 transition-transform duration-200 group-data-[state=open]:rotate-180" />
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className="overflow-hidden data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp">
            <div className="space-y-4 text-sm">
              <p className="text-sm text-gray-600 mb-4">
                Effective Date: 18th November 2024
                <br />
                Version: 1.01
              </p>
              <p>
                ICROMM Pty Ltd ("ICROMM," "we," "us," or "our") is committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information in accordance with the Privacy Act 1988 (Cth) and the Australian Privacy Principles (APPs). By using our services or providing us with your personal information, you agree to the practices described in this policy.
              </p>

              <h3 className="text-lg font-semibold mt-4">1. Information We Collect</h3>
              <p>
                We collect personal information and other data to provide and improve our services. The types of data we may collect include:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>Personal Information: This includes your name, email address, contact information, and other details necessary to create and manage your account, as well as to communicate with you.</li>
                <li>Data You Provide: When using ICROMM's Beta Software, we may collect data you input into the system, such as construction documentation, Safe Work Method Statements (SWMS), Risk Assessments, and other related content. This data may include personal, sensitive, or business-related information, depending on the nature of the content you submit.</li>
                <li>Usage Data: This includes information about how you interact with the Beta Software, such as system logs, performance data, error reports, and analytics to help us improve the software's functionality.</li>
              </ul>

              <h3 className="text-lg font-semibold mt-4">2. How We Use Your Information</h3>
              <p>
                We may use the information we collect for the following purposes:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>To provide and improve our services: We use your information to operate, maintain, and enhance the Beta Software and related services.</li>
                <li>To communicate with you: We may use your email address to send updates, notifications, or customer support related to your account or the Beta Software.</li>
                <li>To generate documentation: The data you provide may be processed by the AI models to generate Safe Work Method Statements (SWMS), Risk Assessments, and other documentation.</li>
                <li>To ensure compliance: We may use your data to ensure compliance with applicable legal and regulatory requirements, including those related to data protection and privacy laws.</li>
              </ul>

              <h3 className="text-lg font-semibold mt-4">3. Use of External AI Models and Third-Party Services</h3>
              <p>
                In order to provide the functionality of the Beta Software, we may process or transmit the data you provide to external AI language models and third-party cloud-based services. These external services may be located in different jurisdictions, some of which may not offer the same level of data protection as Australia.
              </p>
              <p>
                External AI Models: The Beta Software uses AI models to generate outputs based on your input data. While we take steps to protect your information, we recommend that you do not input any sensitive or confidential data (such as personal details, business secrets, or other confidential information) into the system.
              </p>
              <p>
                Third-Party Services: Information you input may be processed by third-party systems for tasks such as data analysis and model improvement. We cannot guarantee the security of data once it is transmitted to third-party services and do not take responsibility for any misuse or loss of data as a result of using these services.
              </p>

              <h3 className="text-lg font-semibold mt-4">4. Confidentiality and Security of Your Data</h3>
              <p>
                We are committed to protecting your personal information and have implemented reasonable security measures to prevent unauthorized access, disclosure, alteration, or destruction of your personal data. However, as data is transmitted to external AI systems and third-party services, you acknowledge and accept the inherent risks involved in such transmissions.
              </p>
              <p>
                We recommend that you do not input confidential, sensitive, or personal information that is subject to privacy laws or contractual confidentiality requirements. If you do choose to input such data, you do so at your own risk, and ICROMM Pty Ltd is not liable for any loss or misuse of that data.
              </p>

              <h3 className="text-lg font-semibold mt-4">5. Sharing Your Information</h3>
              <p>
                We do not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>With service providers: We may share your information with third-party providers of cloud services or AI models to support the functionality of the Beta Software.</li>
                <li>As required by law: We may disclose your information if required to do so by law, such as in response to a subpoena, court order, or a legal request from a regulatory authority.</li>
              </ul>

              <h3 className="text-lg font-semibold mt-4">6. Retention of Your Information</h3>
              <p>
                We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law. Once your personal information is no longer required, we will securely delete or anonymize it in accordance with applicable data retention policies.
              </p>

              <h3 className="text-lg font-semibold mt-4">7. Your Rights and Choices</h3>
              <p>
                Under the Privacy Act 1988 (Cth), you have the right to:
              </p>
              <ul className="list-disc pl-6 mt-2">
                <li>Access your personal information: You can request access to the personal information we hold about you, subject to certain exceptions allowed by law.</li>
                <li>Request corrections: If your personal information is inaccurate, incomplete, or out of date, you can request that we correct it.</li>
                <li>Request deletion: You may request the deletion of your personal information, subject to any legal or contractual obligations that may prevent us from deleting the data.</li>
              </ul>
              <p>
                To exercise any of these rights, please contact us using the details provided below.
              </p>

              <h3 className="text-lg font-semibold mt-4">8. Data Security and Third-Party Services</h3>
              <p>
                While ICROMM Pty Ltd takes appropriate steps to ensure the security of your data, you acknowledge that once your information is transmitted to external systems, it may be subject to risks beyond our control, including unauthorized access or misuse by third parties. We strongly recommend that you do not enter sensitive or confidential information into the Beta Software.
              </p>

              <h3 className="text-lg font-semibold mt-4">9. Changes to This Privacy Policy</h3>
              <p>
                ICROMM Pty Ltd may update this Privacy Policy from time to time. Any changes will be posted on our website, and the updated policy will reflect the effective date of the changes. We encourage you to review this policy periodically to stay informed about how we are protecting your data.
              </p>

              <h3 className="text-lg font-semibold mt-4">10. Contact Us</h3>
              <p>
                If you have any questions or concerns about this Privacy Policy or how your personal information is handled, please contact us at:
              </p>
              <p>
                ICROMM Pty Ltd<br />
                Email: <br />
                Phone: +61 437 505 393
              </p>
            </div>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </div>
  )
}