import AIDocumentation from "../components/landingPageComponents/ai-documentation";
import CTA from "../components/landingPageComponents/cta";
import Footer from "../components/landingPageComponents/footer";
import Header from "../components/landingPageComponents/header";
import Hero from "../components/landingPageComponents/hero";
import Feature from "../components/landingPageComponents/Feature";


export default function HomePage() {
  return (
    <div className="flex flex-col min-h-screen bg-gray-900 text-white">
      <Header />
      <main className="flex-grow">
        <Hero />
        <Feature />
        <AIDocumentation />
        <CTA />
      </main>
      <Footer />
    </div>
  )
}

