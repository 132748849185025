import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ChevronLeft, ChevronRight, FileText, Settings, Plus, BotMessageSquare, HelpCircle, CreditCard, NotebookPen, MessageCircleReply} from "lucide-react";
import { useAuth } from "../context/AuthContext";
import { FormContext } from '../context/FormContext';
import Logo from "../assets/icrommLogoBlueLarge.png";
import { useUser } from "../context/UserContext";
import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedImage } from '@cloudinary/react';
import { auto } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { Menu, MenuItem, IconButton, Avatar, Divider, ListItemIcon, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import Cookies from "js-cookie";

function UserLayout({ children }) {
  const { resetFormData } = useContext(FormContext);
  const { user, loading, fetchUserDetails, DEFAULT_AVATAR, DEFAULT_LOGO } = useUser();
  const { logout } = useAuth();

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [activeTab, setActiveTab] = useState("/home");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const storedTab = localStorage.getItem("activeTab");
    if (storedTab) {
      setActiveTab(storedTab);
    }
    fetchUserDetails();
  }, [fetchUserDetails]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    localStorage.setItem("activeTab", tab);
  };

  const handleLogout = () => {
    logout();
    localStorage.removeItem("activeTab");
    navigate("/login");
  };

  const handleCreateDocument = () => {
    resetFormData();
    handleTabClick("/home");
    navigate("/home");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleClose();
    handleLogout();
    removeAllCookies();
  };
  
  const removeAllCookies = () => {
    const cookies = document.cookie.split(';');
  
    cookies.forEach((cookie) => {
      const cookieName = cookie.split('=')[0].trim();
      // Add domain and path to ensure correct removal
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.icromm.com;`;
    });
  };
  
  

  const renderProfileImage = () => {
    if (user?.profile_image && user.profile_image.includes('res.cloudinary.com')) {
      const cld = new Cloudinary({
        cloud: {
          cloudName: 'dk2lflesi'
        }
      });

      const img = cld
        .image(user.profile_image.split('/').pop())
        .format('auto')
        .quality('auto')
        .resize(auto().gravity(autoGravity()).width(32).height(32));

      return (
        <AdvancedImage
          cldImg={img}
          className="h-8 w-8 rounded-full object-cover border-2 border-gray-200"
        />
      );
    }

    return (
      <img
        src={DEFAULT_AVATAR}
        alt="User"
        className="h-8 w-8 rounded-full object-cover border-2 border-gray-200"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = DEFAULT_AVATAR;
        }}
      />
    );
  };

  // const handleRedirectToNextApp = () => {
  //   const token = Cookies.get("token");
  //   if (token) {
  //     // const nextAppUrl = `http://localhost:4000?token=${encodeURIComponent(token)}`;
  //     const nextAppUrl = `https://assistance.icromm.com?token=${encodeURIComponent(token)}`;
  //     window.location.href = nextAppUrl;
  //   } else {
  //     alert("User is not authenticated.");
  //   }
  // };

  const handleRedirectToNextApp = () => {
    try {
      const token = Cookies.get("token"); // If you need to keep using "token"
      if (!token) {
        alert("User is not authenticated.");
        return;
      }
  
      // Set the cookie for the assistance subdomain
      Cookies.set("token", token, {
        sameSite: "none",
        secure: true,
        domain: ".icromm.com",
        path: "/"
      });
  
      // Construct the URL with token
      const nextAppUrl = new URL("https://assistance.icromm.com");
      nextAppUrl.searchParams.set("token", token);
      
      console.log("Redirecting to assistance app...");
      window.location.href = nextAppUrl.toString();
  
    } catch (error) {
      console.error("Error during redirect:", error);
      alert("An error occurred while redirecting. Please try again.");
    }
  };

  return (
    <div>
      <div className="flex h-screen bg-gray-100">
        {/* Sidebar */}
        <div
          className={`bg-white transition-all duration-300 ease-in-out shadow-md ${
            isSidebarCollapsed ? "w-16" : "w-48"
          }`}
        >
          <div className="flex items-center mt-3 justify-between p-4">
            <h2
              className={`text-2xl mt-3 ml-3 font-bold ${
                isSidebarCollapsed ? "hidden" : "block"
              }`}
            >
              <Link to="/user-dashboard">Menu</Link>
            </h2>
            <button
              className="p-1 rounded-full hover:bg-gray-200 focus:outline-none"
              onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
              aria-label={
                isSidebarCollapsed ? "Expand sidebar" : "Collapse sidebar"
              }
            >
              {isSidebarCollapsed ? (
                <ChevronRight className="h-4 w-4" />
              ) : (
                <ChevronLeft className="h-4 w-4" />
              )}
            </button>
          </div>
          <nav className="space-y-5 mt-2 p-2">
            <Link
              to="/home"
              className={`flex items-center space-x-2 rounded-lg px-2 py-2 text-gray-600 hover:bg-gray-100 ${
                activeTab === "/home" ? "bg-gray-200" : ""
              }`}
              onClick={handleCreateDocument}
            >
              <Plus className="h-5 w-5" />
              {!isSidebarCollapsed && <span>Create Document</span>}
            </Link>
            <Link
              to="/user-dashboard/all-documents"
              className={`flex items-center space-x-2 rounded-lg px-2 py-2 text-gray-600 hover:bg-gray-100 ${
                activeTab === "/user-dashboard/all-documents"
                  ? "bg-gray-200"
                  : ""
              }`}
              onClick={() => handleTabClick("/user-dashboard/all-documents")}
            >
              <FileText className="h-5 w-5" />
              {!isSidebarCollapsed && <span>All Documents</span>}
            </Link>
            <Link
              to="/user-dashboard/buy-token"
              className={`flex items-center space-x-2 rounded-lg px-2 py-2 text-gray-600 hover:bg-gray-100 ${
                activeTab === "/user-dashboard/buy-token" ? "bg-gray-200" : ""
              }`}
              onClick={() => handleTabClick("/user-dashboard/buy-token")}
            >
              <CreditCard className="h-5 w-5" />
              {!isSidebarCollapsed && <span>Buy Completions</span>}
            </Link>
            <Link
              to="#"
              className={`flex items-center space-x-2 rounded-lg px-2 py-2 text-gray-600 hover:bg-gray-100 ${
                activeTab === "/user-dashboard/buy-token" ? "bg-gray-200" : ""
              }`}
              onClick={handleRedirectToNextApp}
            >
              <BotMessageSquare className="h-5 w-5" />
              {!isSidebarCollapsed && <span>ICROMM AI</span>}
            </Link>
            <Link
              to="/user-dashboard/settings"
              className={`flex items-center space-x-2 rounded-lg px-2 py-2 text-gray-600 hover:bg-gray-100 ${
                activeTab === "/user-dashboard/settings" ? "bg-gray-200" : ""
              }`}
              onClick={() => handleTabClick("/user-dashboard/settings")}
            >
              <Settings className="h-5 w-5" />
              {!isSidebarCollapsed && <span>Settings</span>}
            </Link>
            <Link
              to="/user-dashboard/guide"
              className={`flex items-center space-x-2 rounded-lg px-2 py-2 text-gray-600 hover:bg-gray-100 ${
                activeTab === "/user-dashboard/guide" ? "bg-gray-200" : ""
              }`}
              onClick={() => handleTabClick("/user-dashboard/guide")}
            >
              <HelpCircle className="h-5 w-5" />
              {!isSidebarCollapsed && <span>Guide</span>}
            </Link>
            <Link
              to="/user-dashboard/feedback"
              className={`flex items-center space-x-2 rounded-lg px-2 py-2 text-gray-600 hover:bg-gray-100 ${
                activeTab === "/user-dashboard/feedback" ? "bg-gray-200" : ""
              }`}
              onClick={() => handleTabClick("/user-dashboard/feedback")}
            >
              <MessageCircleReply className="h-5 w-5"/>
              {!isSidebarCollapsed && <span>Feedback</span>}
            </Link>
            <Link
              to="/user-dashboard/agreements"
              className={`flex items-center space-x-2 rounded-lg px-2 py-2 text-gray-600 hover:bg-gray-100 ${
                activeTab === "/user-dashboard/agreements" ? "bg-gray-200" : ""
              }`}
              onClick={() => handleTabClick("/user-dashboard/agreements")}
            >
              <NotebookPen className="h-5 w-5"/>
              {!isSidebarCollapsed && <span>Agreements</span>}
            </Link>
          </nav>
        </div>

        {/* Main content */}
        <div className="flex flex-1 flex-col overflow-hidden">
          {/* Header */}
          <header className="flex items-center pr-9 justify-between bg-white p-4 shadow">
            <div className="flex-1 text-center">
              <img src={Logo} alt="Logo" className="mx-auto h-14" />
            </div>
            <div className="flex items-center relative">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <div className="flex items-center space-x-2">
                  <Typography variant="body1" className="text-gray-700">
                    {loading ? "Loading..." : `${user?.first_name} ${user?.last_name}` || "Name"}
                  </Typography>
                  <div className="ml-2">
                    {renderProfileImage()}
                  </div>
                </div>
              </IconButton>
            </div>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={() => navigate('/user-dashboard/settings')}>
                <ListItemIcon>
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                Profile
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogoutClick}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </header>

          {/* Modified main content wrapper with padding bottom for footer */}
          <main className="flex-1 overflow-y-auto p-7 pb-16">{children}</main>

          {/* New Footer */}
          <footer className="fixed bottom-0 w-full bg-white p-4 text-center pr-60 text-sm text-gray-600 border-t">
            By using this software, you acknowledge and agree to the privacy terms and software agreement available at:{' '}
            <Link to="/user-dashboard/agreements" className="text-blue-600 hover:underline">
              https://www.icromm.com/user-dashboard/agreements
            </Link>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default UserLayout;
