"use client";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { Menu } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLogin = () => {
    window.location.href = "https://www.icromm.com/login";
  };

  const handleGetStarted = () => {
    window.location.href = "https://www.icromm.com/signup";
  };

  return (
    <header
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled ? "bg-gray-900/80 backdrop-blur-md" : "bg-transparent"
      }`}
    >
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link href="/" className="h-8">
          <img
            src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/IcrommLogoFinal_white-VZVQ79BqHEmM3s1uu7jLNNJ6YLCAD0.png"
            alt="Icromm Logo"
            width={120}
            height={32}
            className="h-8 w-auto"
            priority
          />
        </Link>
        <div className="flex items-center space-x-4">
          <Button
            variant="secondary"
            className="hidden md:inline-flex"
            onClick={handleLogin}
          >
            Log In
          </Button>
          <Button onClick={handleGetStarted}>Get Started</Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon">
                <Menu className="h-6 w-6" />
                <span className="sr-only">Open menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="bg-[#1f2937] text-white !bg-[#1f2937] border-none shadow-lg"
            >
              <DropdownMenuItem
                onSelect={() => {
                  window.location.hash = "";
                  window.location.hash = "advanced-documentation";
                }}
                className="hover:bg-[#374151] hover:text-[#facc14] focus:bg-[#374151] focus:text-[#facc14] transition-colors duration-200 cursor-pointer"
              >
                Our AI-Powered Solutions
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => {
                  window.location.hash = "";
                  window.location.hash = "advanced-documentation";
                }}
                className="hover:bg-[#374151] hover:text-[#facc14] focus:bg-[#374151] focus:text-[#facc14] transition-colors duration-200 cursor-pointer"
              >
                Advanced Documentation
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => {
                  window.location.hash = "";
                  window.location.hash = "ai-powers-hse";
                }}
                className="hover:bg-[#374151] hover:text-[#facc14] focus:bg-[#374151] focus:text-[#facc14] transition-colors duration-200 cursor-pointer"
              >
                AI Powers HSE
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => {
                  window.location.hash = "";
                  window.location.hash = "smarter-work-flow";
                }}
                className="hover:bg-[#374151] hover:text-[#facc14] focus:bg-[#374151] focus:text-[#facc14] transition-colors duration-200 cursor-pointer"
              >
                Smarter Work Flow
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => {
                  window.location.hash = "";
                  window.location.hash = "finance-and-commercial";
                }}
                className="hover:bg-[#374151] hover:text-[#facc14] focus:bg-[#374151] focus:text-[#facc14] transition-colors duration-200 cursor-pointer"
              >
                Finance and Commercial
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => {
                  window.location.hash = "";
                  window.location.hash = "quality-and-production";
                }}
                className="hover:bg-[#374151] hover:text-[#facc14] focus:bg-[#374151] focus:text-[#facc14] transition-colors duration-200 cursor-pointer"
              >
                Quality and Production
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>
  );
}
