import React from 'react';
import hero2new from '../../assets/hero2new.jpeg'
import { Button } from '../../components/ui/button'
import { motion } from 'framer-motion';

export default function CTA() {
  const handleScheduleDemo = () => {
    const subject = encodeURIComponent('Request for Demo: AI Solutions for Construction');
    const body = encodeURIComponent(`Hello, my name is [Insert Name] from [Insert Company]. I am reaching out to arrange a date and time for your representatives to provide a demonstration of your technology solutions in AI and related technologies. We are keen to gain a deeper understanding of your offerings and how they align with our needs and objectives.

Please let me know a convenient time for your team, and we can coordinate the details accordingly. Your expertise in this area is of particular interest as we explore potential opportunities for collaboration.`);
    
    window.location.href = `mailto:info@icromm.com?subject=${subject}&body=${body}`;
  };

  const handleContactTeam = () => {
    const subject = encodeURIComponent('Enquiry About Your Solutions');
    const body = encodeURIComponent(`Hi Nathan,

My name is [Insert Name] from [Insert Company], and I can be reached via [Insert Preferred Contact Method]. I am interested in learning more about your products and exploring how they might benefit our business. Please let me know a convenient time for us to connect.

Kind regards,
[Insert Name]`);
    
    window.location.href = `mailto:nathan.spek@icromm.com?subject=${subject}&body=${body}`;
  };

  return (
    <section 
      id="contact" 
      className="relative py-20 text-white"
    >
      <div className="absolute inset-0 z-0">
        <img
          src={hero2new}
          alt="Construction machinery with technological overlay"
          className="object-cover w-full h-full"
        />
      </div>
      <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
      <div className="container mx-auto px-4 text-center relative z-20">
        <motion.h2 
          className="text-4xl font-bold mb-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Ready to Transform Your Construction Projects?
        </motion.h2>
        <motion.p 
          className="text-xl mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Get in touch with us to learn how our AI solutions can benefit your business.
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <Button className="bg-secondary text-black hover:bg-gray-300 py-5 px-7 rounded-lg mr-4" onClick={handleScheduleDemo}>
            Schedule a Demo
          </Button>
          <Button className="bg-black text-white hover:bg-gray-900 py-5 px-7 rounded-lg" onClick={handleContactTeam}>
            Contact Our Team
          </Button>
        </motion.div>
      </div>
    </section>
  );
}
