import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useUser } from '../context/UserContext';
import { FaFile, FaCalendar, FaCoins, FaArrowLeft, FaProjectDiagram, FaFileAlt } from 'react-icons/fa';
import { Download } from 'lucide-react';
import Logo from '../assets/icrommLogoBlueLarge.png'; // Import Logo at the top of the file

// Utility functions
function getBackgroundColor(value) {
  const highRisks = [
    "H-11", "H-16", "H-12", "H-17", "H-13", 
    "H-14", "H-10", "H-15",
  ];
  const mediumRisks = ["M-7", "M-8", "M-9", "M-6"];
  const lowRisks = ["L-4", "L-2", "L-1", "L-5", "L-3"];
  const extremeRisks = [
    "E-20", "E-23", "E-25", "E-21", "E-24",
    "E-18", "E-22", "E-19",
  ];

  if (highRisks.includes(value)) return "#ffc000";
  if (mediumRisks.includes(value)) return "#ffd966";
  if (lowRisks.includes(value)) return "#a9d08e";
  if (extremeRisks.includes(value)) return "red";
  return "";
}

function getHierarchyBackgroundColor(value) {
  if (value === "1") return "#a9d08e";
  if (value === "2") return "#ffd966";
  if (value === "3") return "red";
  return "";
}

function extractKeywords(url) {
  // Remove common prefixes and file extensions
  const cleanUrl = url
    .replace(/^(https?:\/\/)?(www\.)?/, "")
    .replace(/\.[^/.]+$/, "");
  // Split the remaining string into words
  const words = cleanUrl.split(/[-.]+/);
  // Return up to 3 words, joined with spaces
  return words.slice(0, 3).join(" ");
}

const DocumentCard = ({ document: docData, isAdmin, authToken }) => {
  const [downloadError, setDownloadError] = useState(null);

  const handleDownloadPDF = async (documentId) => {
    try {
      // First, fetch the document data
      const documentResponse = await fetch(
        `https://www.icromm.com/api/admin/documents/${documentId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (!documentResponse.ok) {
        throw new Error(`Failed to fetch document data: ${documentResponse.statusText}`);
      }

      const documentData = await documentResponse.json();

      // Convert document data to HTML
      const htmlContent = convertDocumentToHtml(documentData);

      // Send HTML and document data to server for PDF conversion
      const pdfResponse = await fetch(
        "https://www.icromm.com/api/convert-html-to-pdf",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            html_content: htmlContent,
            document_data: documentData,
          }),
        }
      );

      if (!pdfResponse.ok) {
        const errorData = await pdfResponse.text();
        throw new Error(`Failed to generate PDF: ${errorData || pdfResponse.statusText}`);
      }

      const pdfBlob = await pdfResponse.blob();
      const url = window.URL.createObjectURL(pdfBlob);
      const a = window.document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // Use project name in the downloaded file name
      const fileName = docData.project_details?.projectName || 'document';
      const timestamp = new Date().toISOString().split('T')[0];
      a.download = `${fileName}_${timestamp}.pdf`;
      
      window.document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      window.document.body.removeChild(a);
    } catch (err) {
      console.error("Error downloading PDF:", err);
      setDownloadError(`Error downloading PDF: ${err.message}`);
    }
  };

  const convertDocumentToHtml = (documentData) => {
    // console.log("Document Data:", JSON.stringify(documentData, null, 2));

    const projectDetailsColor =
      documentData.project_details?.color || "#f5a623";
    // console.log("High Level Risks Data:", JSON.stringify(documentData.high_level_risks, null, 2));
    const highLevelRisksColor =
      documentData.high_level_risks?.color || "#f5a623";
    const personsInvolvedColor =
      documentData.persons_involved_card?.color || "#f5a623";
    const ppeColor = documentData.ppe_card?.color || "#f5a623";
    // console.log("PPE Data:", JSON.stringify(documentData.ppe_card, null, 2));
    const constructionPlantEquipmentColor =
      documentData.construction_plant_equipment?.color || "#f5a623";
    // console.log("Construction Plant Equipment Data:", JSON.stringify(documentData.construction_plant_equipment, null, 2));
    const legislativeReferencesColor =
      documentData.legislative_list?.color || "#f5a623";
    // console.log("Legislative Data:", JSON.stringify(documentData.legislative_list, null, 2));
    // console.log(
    //   "Persons Involved Data:",
    //   JSON.stringify(documentData.persons_involved_card, null, 2)
    // );
    const inductionSignOnColor =
      documentData.induction_sign_on?.color || "#f5a623";
    // console.log(
    //   "Required Permits Data:",
    //   JSON.stringify(documentData.required_permits, null, 2)
    // );

    const defaultDocumentLogo = "https://asset.cloudinary.com/dk2lflesi/9e0f9aa5de08085e83b56d2eab297bbd";

    const documentColor = documentData.color || "#f5a623";
    // console.log(documentColor);

    const taskDetailsHtml = `
      <div class="task-details-card">
        <div class="card-header">
          <div class="header-content">
            <i class="fas fa-tasks"></i>
            <span>Task Details</span>
          </div>
          <i class="fas fa-chevron-up"></i>
        </div>

        <div class="card-content">
          <table class="task-details-table">
            <tr>
              <td class="label-cell">Task Details</td>
              <td class="content-cell">
                <textarea class="readonly-text" readonly>${documentData.task_details || ''}</textarea>
              </td>
            </tr>
            <tr>
              <td class="label-cell">Location and Site Details</td>
              <td class="content-cell">
                <textarea class="readonly-text" readonly>${documentData.location_details || ''}</textarea>
              </td>
            </tr>
            <tr>
              <td class="label-cell">Must Include Tasks</td>
              <td class="content-cell">
                <textarea class="readonly-text" readonly>${documentData.include_tasks || 'Optional'}</textarea>
              </td>
            </tr>
            <tr>
              <td class="label-cell">Must Exclude Tasks</td>
              <td class="content-cell">
                <textarea class="readonly-text" readonly>${documentData.exclude_tasks || 'Optional'}</textarea>
              </td>
            </tr>
            <tr>
              <td class="label-cell">Number of Activities</td>
              <td class="content-cell">${documentData.number_of_activities || '0'}</td>
            </tr>
            <tr>
              <td class="label-cell">State</td>
              <td class="content-cell">${documentData.selected_state || ''}</td>
            </tr>
          </table>
        </div>
      </div>
    `;

    return `
      <html>
      <head>
        <style>

          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }
        
            body {
            margin: 0;
            padding: 20px;
            font-family: Arial, sans-serif;
          }

.header-card {
  display: flex;
  background-color: ${documentColor};
  border-radius: 10px;
  padding: 15px 10px; /* Increased padding for more height */
  align-items: center;
  gap: 15px;
  min-height: 120px; /* Increased card height */
}

.logo-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 25px 20px;
  width: 30%;
  border-radius: 10px;
  height: 150px; /* Fixed height */
  min-height: 150px; /* Same as height to keep it consistent */
}

.logo {
  width: 100%;
  height: 100%;
  max-width: 200px; /* Limit maximum width */
  max-height: 120px; /* Limit maximum height */
  object-fit: contain; /* Maintains aspect ratio */
  display: block;
  margin: auto;
  padding: 10px;
}

 .statement-section {
        flex-grow: 1;
        padding: 15px;
        display: flex;
        flex-direction: column;
      }

      .statement-title {
        font-size: 12px;
        font-weight: bold;
        color: #ffffff;
        margin: 0 0 10px 0;
      }

      .input-area {
        width: 80%; /* Expanded input area */
        padding: 10px;
        border: none;
        border-radius: 5px;
      }

      .header-table {
        width: 100%;
        border-collapse: collapse; /* Removes cell borders */
      }



      
.project-details-card {
  background-color: ${documentColor};
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 5px solid ${documentColor};
}

.card-header {
  display: flex;
  align-items: center;
  padding: 15px;
  color: #FFFFFF;
  background-color: ${documentColor};
}

.card-header span {
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
}

.card-header i {
  font-size: 18px;
}

.card-header .fa-chevron-up {
  margin-left: auto;
}

.card-content {
  background-color: #FFFFFF;
  padding: 20px;
  break-inside: auto;
}

/* Yellow border section without yellow background */
.yellow-border {
  border: 2px solid ${documentColor};
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 15px;
  background-color: transparent; /* Changed to transparent */
}

/* White border section with gray background */
.white-border {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  background-color: #f0f0f0; /* Added gray background */
}

.form-table {
  width: 100%;
  border-collapse: collapse; /* No borders between cells */
}

.form-field {
  padding: 10px;
  vertical-align: top;
}

.form-field.small {
  width: 30%; /* Small field width */
}

.form-field.full-width {
  width: 100%; /* Address field spans full width */
}

label {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
  color: #666;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.date-input {
  position: relative;
}

.date-input input {
  padding-right: 30px;
}

.date-input i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}


body {
  margin: 0;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.risk-management-schedule-card {
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: ${documentColor};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 5px solid ${documentColor};;
}

.risk-schedule-text-area {
  width: 100%;                
  padding: 8px;               
  border: 2px solid #ccc;     
  border-radius: 4px;         
  font-size: 12px;            
  box-sizing: border-box;     
  resize: none;               
  overflow: hidden;           
  line-height: 1.5;       
  background-color: #fff; 
}

.outer-yellow-border {
  border: 2px solid ${documentColor}; 
  border-radius: 8px; /* Rounded corners for the border */
  padding: 10px; /* Padding inside the border */
  margin-bottom: 20px; /* Space below each card */
}

.field-group, .controls-group {
  border: 2px solid #ccc; /* Gray border */
  border-radius: 5px;
  padding: 5px; /* Space inside the border */
  margin: 10px 0; /* Gap between the groups */
}

.controls-procedures {
  white-space: pre-wrap;  /* Keeps text wrapping properly */
  word-wrap: break-word;  /* Avoids long words from breaking layout */
  font-family: inherit;   /* Matches the default font of input elements */
  font-size: 12px;        /* Adjust to match other inputs */
  line-height: 1.5;       /* Same as typical inputs for consistency */
  background-color: #fff;
  padding: 8px;
  box-sizing: border-box;
  margin: 0;
}

.schedule-card-header {
  display: flex;
  align-items: center;
  padding: 15px;
  color: #ffffff;
  background-color: ${documentColor};
}

.schedule-header-left {
  display: flex;
  font-sizs
  align-items: center;
  gap: 10px;
}

.schedule-card-header i {
  font-size: 18px;
}

.schedule-card-header .fa-chevron-up {
  margin-left: auto;
}

.schedule-card-content {
  background-color: #f0f0f0;
  padding: 20px;
}

.risk-schedule-table {
  width: 100%;
  border-collapse: collapse;
}

.field-small {
  width: 30%;
  padding: 5px 10px;
  vertical-align: top;
}

.field-medium {
  width: 20%;
  padding: 5px 10px;
  vertical-align: top;
}

.field-large {
  width: 50%;
  padding: 5px 10px;
  vertical-align: top;
}

.field-full-width {
  width: 100%;
  padding: 10px;
}

label {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
  color: #666;
}

input[type="text"] {
  width: 100%;
  padding: 6px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  box-sizing: border-box;
}

textarea.auto-resize {
  width: 100%;
  padding: 6px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  box-sizing: border-box;
  resize: none;
}



.plant-equipment-container {
  background-color: #F0F0F0;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 20px;
  border: 5px solid ${documentColor};
}

.plant-header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 15px;
  background-color: ${documentColor};
  color: #FFFFFF;
}

.plant-header-left {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  align-items: center;
}

.plant-content {
  background-color: #F0F0F0;
  padding: 20px;
}

.plant-grid {
  display: grid;
  grid-template-columns: 200px 200px 200px; /* Fixed width columns for consistent PDF layout */
  gap: 20px;
}

.plant-item {
  display: grid;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  break-inside: avoid; /* Avoid breaking cards across pages */
}

.plant-item-grid {
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 15px;
}

.plant-field {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 5px;
}

.plant-field label {
  font-size: 12px;
  color: #666;
  font-weight: bold;
}

.plant-field textarea {
  width: 100%;
  min-height: 80px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  resize: none;
  background-color: #f8f8f8;
}




.legislation-card {
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 5px solid ${documentColor};
}

/* Header styling */
.legislation-card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  color: #FFFFFF;
  background-color: ${documentColor}; 
}

.legislation-card .header-left {
  display: flex;
  align-items: center;
}

.legislation-card .header-left i {
  font-size: 18px;
  margin-right: 10px;
}

.legislation-card .card-header span {
  font-size: 18px;
  font-weight: bold;
}

/* Card content styling */
.legislation-card .card-content {
  background-color: #F0F0F0; /* Content background color */
  padding: 20px;
}

.legislation-card-auto-grow-text {
  display: block;
  width: 100%; /* Full width */
  padding: 8px; /* Padding inside the textarea */
  border: 1px solid #ccc; /* Border color */
  border-radius: 4px; /* Rounded corners */
  font-size: 12px; /* Font size */
  line-height: 1.5; /* Line height for readability */
  background-color: #fff; /* Background color */
  min-height: 60px; /* Minimum height */
  overflow: hidden; /* Hides scrollbars */
  resize: none; /* Prevents manual resizing */
  box-sizing: border-box; /* Ensures padding and border are included in total width/height */
}

/* Optional: Add focus styles */
.legislation-card-auto-grow-text:focus {
  border-color: #007bff; /* Change border color on focus */
  outline: none; /* Remove default outline */
}

/* Legislation item card styling */
.legislation-item-card {
  border: 2px solid ${documentColor}; /* Yellow border for each legislation item card */
  border-radius: 5px;
  margin-bottom: 15px; /* Space between each legislation item card */
  padding: 15px; /* Padding inside each card */
}

/* Table styling */
.legislation-table {
  width: 100%;
  border-collapse: collapse;
}

/* Cell styling */
.cell-label {
  padding: 10px;
}

/* Input and label styling */
.cell-label label {
  display: block;
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}

.legislation-table input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  height: 36px;
  box-sizing: border-box;
}

/* Auto-growing text field styling */
.auto-grow-text {
  display: block;
  white-space: pre-wrap; /* Preserves whitespace and wraps text */
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  line-height: 1.5;
  box-sizing: border-box;
  min-height: 60px; /* Initial minimum height */
  overflow: hidden; /* Hides scrollbars */
}




.ppe-card-container {
  background-color: #F0F0F0;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 20px;
  border: 5px solid ${documentColor};
}

/* Header styling */
.ppe-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${documentColor}; 
  padding: 15px;
  color: #FFFFFF;
}

.header-content {
  display: flex;
  align-items: center;
}

.header-content i {
  font-size: 18px;
  margin-right: 10px;
}

.ppe-card-header span {
  font-size: 18px;
  font-weight: bold;
}

/* Card body styling */
.ppe-card-body {
  background-color: #F0F0F0;
  padding: 20px;
}

.ppe-card-container-auto-grow-text {
  display: block;
  width: 100%; /* Full width */
  padding: 8px; /* Padding inside the textarea */
  border: 1px solid #ccc; /* Border color */
  border-radius: 4px; /* Rounded corners */
  font-size: 12px; /* Font size */
  line-height: 1.5; /* Line height for readability */
  background-color: #fff; /* Background color */
  min-height: 60px; /* Minimum height */
  overflow: hidden; /* Hides scrollbars */
  resize: none; /* Prevents manual resizing */
  box-sizing: border-box; /* Ensures padding and border are included in total width/height */
}

/* Optional: Add focus styles */
.ppe-card-container-auto-grow-text:focus {
  border-color: #007bff; /* Change border color on focus */
  outline: none; /* Remove default outline */
}

/* Individual PPE item card styling */
.ppe-item-card {
  border: 2px solid ${documentColor}; 
  border-radius: 5px;
  margin-bottom: 15px; 
  padding: 15px; 
}

/* Table styling */
.ppe-table {
  width: 100%;
  border-collapse: collapse;
}

/* Cell styling */
.ppe-cell {
  padding: 10px;
}

/* Label styling */
.ppe-cell label {
  display: block;
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}

/* Input styling */
.ppe-table input[type="text"] {
  width: 100%;
  height: 36px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  box-sizing: border-box;
}

/* Auto-growing text field styling */
.auto-grow-text {
  display: block;
  white-space: pre-wrap; 
  word-wrap: break-word;
  background-color: #FFFFFF;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
  line-height: 1.5;
  box-sizing: border-box;
  min-height: 60px; 
  overflow: hidden; 
}





/* Card container styling */
.permit-card-container {
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 5px solid ${documentColor}; 
}

/* Header styling */
.permit-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${documentColor}; 
  padding: 15px;
  color: #FFFFFF;
}

.header-content {
  display: flex;
  align-items: center;
}

.header-content i {
  font-size: 18px;
  margin-right: 10px;
}

.permit-card-header span {
  font-size: 18px;
  font-weight: bold;
}

/* Card body styling */
.permit-card-body {
  background-color: #F0F0F0;
  padding: 20px;
}

/* Individual permit item card styling */
.permit-item-card {
  border: 2px solid ${documentColor}; 
  border-radius: 5px;
  margin-bottom: 15px; 
  padding: 15px; 
}

/* Table styling */
.permit-table {
  width: 100%;
  border-collapse: collapse;
}

/* Cell styling */
.permit-cell {
  padding: 10px;
}

/* Label styling */
.permit-cell label {
  display: block;
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}

/* Input styling */
.permit-table input[type="text"] {
  width: 100%;
  height: 36px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  box-sizing: border-box;
}

/* Auto-growing text field styling */
.auto-grow-text {
  display: block;
  white-space: pre-wrap; 
  word-wrap: break-word;
  background-color: #FFFFFF;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
  line-height: 1.5;
  box-sizing: border-box;
  min-height: 60px; /* Initial minimum height */
  overflow: hidden; /* Hides scrollbars */
}






        .risk-tables-card {
          background-color: #FFFFFF;
          border-radius: 10px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          margin-top: 20px;
          margin-bottom: 20px;
          border: 5px solid ${documentColor};
        }

        .risk-tables-card .card-header {
          background-color: ${documentColor};
          padding: 15px;
          color: #FFFFFF;
          height: 60px;
          display: flex;
          align-items: center;
        }

        .risk-tables-card .header-left {
          display: flex;
          align-items: center;
        }

        .risk-tables-card .header-left i {
          font-size: 18px;
          margin-right: 10px;
        }

        .risk-tables-card .card-header span {
          font-size: 18px;
          font-weight: bold;
        }

        .risk-tables-card .card-content {
          background-color: #E9EAEC;
          padding: 20px;
        } 

        .risk-tables-card h3 {
          text-align: center;
          padding: 7px;
          border: 1px solid black;
          background-color: ${documentColor};
          color: #FFFFFF;
          font-weight: bold;
          font-family: "Roboto, sans-serif";
          font-size: 11px;
          margin-bottom: 0;
        }

        .risk-tables-card h4 {
          background-color: ${documentColor};
          color: white;
          padding: 5px;
          margin: 0 0 10px 0;
          font-size: 12px;
          text-align: center;
        }

        .risk-tables-card table {
          width: 100%;
          border-collapse: collapse;
          font-family: "Roboto, sans-serif";
          font-size: 11px;
        }

        .risk-tables-card th,
        .risk-tables-card td {
          padding: 5px;
          border: 1px solid black;
          text-align: center;
          vertical-align: middle;
        }

        .risk-tables-card th {
          background-color: #d9d9d9;
          font-weight: bold;
        }

        .risk-rating-table, .likelihood-definition, .risk-level-action, .hierarchy-of-controls, .consequence-definition {
          border: 2px solid ${documentColor};
          padding: 16px;
          border-radius: 3px;
          margin-bottom: 16px;
          background-color: #FFFFFF;
          margin-right: 16px;
          margin-left: 16px;
        }

        

        .risk-tables-card .yellow { background-color: #ffd966; }
        .risk-tables-card .orange { background-color: #ffc000; }
        .risk-tables-card .red { background-color: red; color: white; }
        .risk-tables-card .green { background-color: #a9d08e; }

        .risk-level-action .extreme td:first-child { background-color: #FF0000; color: white; }
        .risk-level-action .high td:first-child { background-color: ${documentColor}; }
        .risk-level-action .moderate td:first-child { background-color: #FFFF00; }
        .risk-level-action .low td:first-child { background-color: #90EE90; }

        .risk-level-action td {
          text-align: left;
          padding: 10px;
        }

        .risk-level-action td:first-child {
          font-weight: bold;
          width: 150px;
        }

        .likelihood-definition table td:first-child,
        .likelihood-definition table td:nth-child(2) {
          font-weight: bold;
        }

        .risk-rating-table table td:first-child,
        .risk-rating-table table td:nth-child(2) {
          font-weight: bold;
        }

        /* Add these styles to your existing CSS */

        .consequence-definition table {
          font-size: 10px;
        }

        .consequence-definition th,
        .consequence-definition td {
          padding: 5px;
          vertical-align: top;
        }

        .consequence-definition td:first-child,
        .consequence-definition td:nth-child(2) {
          font-weight: bold;
        }

        .consequence-definition th {
          background-color: ${documentColor};
          color: white;
        }

        .consequence-definition tr:nth-child(even) {
          background-color: #f2f2f2;
        }

        .consequence-definition tr:hover {
          background-color: #ddd;
        }

        .consequence-header {
          background-color: white !important;
          color: red;
        }

        .hierarchy-of-controls table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
            border: 1px solid #ccc;
          }
          
          .hierarchy-of-controls td {
            border: 1px solid #ccc;
            padding: 10px;
            text-align: center;
          }
          
          .hierarchy-of-controls .header td {
            background-color: ${documentColor}; /* Orange */
            font-weight: bold;
            color: black;
          }
          
          .hierarchy-of-controls .side {
            width: 20%;
            font-weight: bold;
          }
          
          .hierarchy-of-controls .center {
            width: 60%;
          }
          
          .hierarchy-of-controls .level-1 { background-color: #a9d08e; }
          .hierarchy-of-controls .level-2 td { background-color: #f0cb7a; }
          .hierarchy-of-controls .level-3 { background-color: red; color: white; }
          
          .hierarchy-of-controls .level-1 .center,
          .hierarchy-of-controls .level-2 .center,
          .hierarchy-of-controls .level-3 .center {
            background-color: #f2b64e;
          }
          
          .hierarchy-of-controls .level-3 .side {
            color: white;
          }
          
          .hierarchy-of-controls .center div:first-child {
            font-weight: bold;
            margin-bottom: 5px;
          }
          
          .hierarchy-of-controls .level-3 .center {
            padding: 5px;
          }
          
          .hierarchy-of-controls .level-3 .center div {
            margin-bottom: 5px;
          }





          .employee-induction-card {
            border-radius: 10px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            margin-top: 20px;
             margin-bottom: 20px;
             border: 5px solid ${documentColor};
          }
          
          .employee-induction-card .card-header {
            padding: 15px;
            color: #FFFFFF;
          }
          
          .employee-induction-card .header-content {
            flex-grow: 1;
          }
          
          .employee-induction-card .header-left {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
          }
          
          .employee-induction-card .header-left i {
            font-size: 18px;
            margin-right: 10px;
          }
          
          .employee-induction-card .card-header span {
            font-size: 18px;
            font-weight: bold;
          }
          
          .employee-induction-card .agreement-text {
            font-size: 12px;
            color: #E0E0E0;
            margin: 0;
            line-height: 1.4;
          }
          
          .employee-induction-card .card-content {
            background-color: #FFFFFF;
            padding: 20px;
          }
          
          .employee-induction-card .induction-table h3 {
            background-color: ${documentColor};
            color: #FFFFFF;
            padding: 10px;
            margin: 0;
            text-align: center;
          }
          
          .employee-induction-card table {
            width: 100%;
            border-collapse: collapse;
          }
          
          .employee-induction-card th,
          .employee-induction-card td {
            border: 1px solid #ccc;
            padding: 10px;
            text-align: left;
          }
          
          .employee-induction-card th {
            background-color: ${documentColor};
            color: #FFFFFF;
          }
          
          .employee-induction-card input[type="text"],
          .employee-induction-card input[type="date"] {
            width: 100%;
            padding: 5px;
            border: 1px solid #ccc;
            border-radius: 4px;
          }
          
          .employee-induction-card input[type="date"] {
            padding: 4px 5px;
          }



        .people-involved-card,
        .form-reviewer-card {
          background-color: ${documentColor};
          border-radius: 10px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          margin-top: 20px;
          margin-bottom: 20px;
          border: 5px solid ${documentColor};
        }

        .people-involved-card .card-header,
        .form-reviewer-card .card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px;
          color: #FFFFFF;
        }

        .people-involved-card .header-left,
        .form-reviewer-card .header-left {
          display: flex;
          align-items: center;
        }

        .people-involved-card .header-left i,
        .form-reviewer-card .header-left i {
          font-size: 18px;
          margin-right: 10px;
        }

        .form-column textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  resize: none; /* Prevents manual resizing */
  overflow: hidden; /* Prevents scrollbars */
}

        .people-involved-card .card-header span,
        .form-reviewer-card .card-header span {
          font-size: 18px;
          font-weight: bold;
        }

        .people-involved-card .card-content,
        .form-reviewer-card .card-content {
          background-color: #FFFFFF;
          padding: 20px;
        }

        .people-involved-card table,
        .form-reviewer-card table {
          width: 100%;
          border-collapse: collapse;
        }

        .people-involved-card th,
        .people-involved-card td,
        .form-reviewer-card th,
        .form-reviewer-card td {
          border: 1px solid #ccc;
          padding: 10px;
          text-align: left;
        }

        .people-involved-card th,
        .form-reviewer-card th {
          background-color: ${documentColor};
          color: #FFFFFF;
        }

        .people-involved-card input[type="text"],
        .form-reviewer-card input[type="text"],
        .form-reviewer-card input[type="date"] {
          width: 100%;
          padding: 5px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }

        .form-reviewer-card input[type="date"] {
          padding: 4px 5px;
        }


.persons-involved-card {
  background-color: #F0F0F0;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 20px;
  border: 5px solid ${documentColor};
}

.inner-group-wrapper {
  border: 2px solid black; 
  border-radius: 8px;
  padding: 15px;
}

.persons-involved-card-group-wrapper {
  display: flex;
  justify-content: space-between;
  border: 2px solid ${documentColor};
  border-radius: 8px;
  padding: 15px;
  margin-top: 10px;
  background-color: #F0F0F0;
}

/* Header styling */
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  color: #FFFFFF;
  background-color: ${documentColor};
}

.header-left {
  display: flex;
  align-items: center;
}

.header-left i {
  font-size: 18px;
  margin-right: 10px;
}

.card-header span {
  font-size: 18px;
  font-weight: bold;
}

/* Content section styling */
.card-content {
  background-color: #F0F0F0;
  padding: 20px;
}

.sections-wrapper {
  border: 2px solid ${documentColor}; 
  padding: 10px; 
  border-radius: 8px;
  margin-top: 15px; 
  margin-bottom: 15px; 
  background-color: #F0F0F0; 
  overflow: hidden; 
  break-inside: auto; 
}

.persons-involved-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  page-break-inside: auto;
  break-inside: auto;
}

/* Form column styling */
.form-column {
  padding: 15px;
  background-color: #F0F0F0; /* Set background to #F0F0F0 */
  border-radius: 5px;
  vertical-align: top;
  box-sizing: border-box;
}

.inner-card-wrapper {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px;
  background-color: #F0F0F0; /* Maintain consistency */
}

/* Label styling */
.form-column label {
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
  display: block;
}

.form-column textarea {
  flex: 1;
  margin: 5px 0;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  resize: none;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-break: break-word;

  /* Allow the textarea to break if needed on page boundaries */
  page-break-inside: auto;
  break-inside: auto;
}

.form-column textarea::placeholder {
  color: #999;
}

.personnel-row {
  page-break-inside: avoid;  /* Prevents a complete row from splitting */
  break-inside: avoid-column; /* For multi-column layouts */
  break-inside: avoid; /* For other layouts */
}

.personnel-row td {
  padding: 10px;
  vertical-align: top;
}

.personnel-row:last-child {
  border-bottom: none;
}



.high-level-risks-card {
  background-color: #F0F0F0;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 20px;
  position: relative;
  border: 5px solid ${documentColor};
}

/* Ensure visibility on print */
@media print {
  .high-level-risks-card {
    display: block !important;
  }
}

/* Header styling */
.high-level-risks-card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  color: #FFFFFF;
}

.high-level-risks-card .header-left {
  display: flex;
  align-items: center;
}

.high-level-risks-card .header-left i {
  font-size: 18px;
  margin-right: 10px;
}

.high-level-risks-card .card-header span {
  font-size: 18px;
  font-weight: bold;
}

/* Table layout */
.risk-table {
  width: 100%;
  border-collapse: separate; /* Use 'separate' to allow better spacing */
  border-spacing: 15px 10px; /* Add horizontal and vertical spacing */
  margin-top: 20px;
}

.risk-table tr {
  border-bottom: 1px solid #ddd;
}

/* Wrapper for each risk card with a yellow border */
.risk-card-wrapper {
  padding: 10px;
  border: 2px solid ${documentColor};
  border-radius: 5px;
  background-color: #F0F0F0;
}

/* Form field styling */
.form-field {
  margin-bottom: 15px;
}

.form-field label {
  display: block;
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}

.risk-card input[type="text"],
.risk-card textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  background-color: #FFFFFF;
  resize: none;
  box-sizing: border-box;
}

/* Adjust height for text areas */
.risk-card input[type="text"] {
  height: 36px;
}

.risk-card textarea {
  min-height: 60px;
  max-height: 150px;
  overflow-y: hidden;
}

/* Remove scrollbar styling */
.risk-card textarea::-webkit-scrollbar {
  display: none;
}

.risk-rating-table-header-black {
  color: black !important;
}





.risk-rating-cell-A {
  font-weight: bold;
  background-color: #cfcfcf;
}

.risk-rating-table-header {
  background-color: #f2f2f2 !important;
}

.likelihood-cell-weight {
  font-weight: normal !important;
}

.risk-action-table-header-color-white {
  color: white !important;
}

.hierarchy-of-controls-level-3-black {
  color: black ;
}

.hierarchy-of-controls-level-3-middle-text-weight-normal {
  font-weight: normal !important;
}

.risk-level-action-table-middle-text-weight-normal {
  font-weight: normal !important;
}


.hierarchy-of-controls table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 1px solid black;  
}
          
.hierarchy-of-controls td {
    border: 1px solid black;  
    padding: 10px;
    text-align: center;
}

.hierarchy-of-controls .header td {
    background-color: ${documentColor};
    font-weight: bold;
    color: black;
    border: 1px solid black;  
}

.hierarchy-of-controls .side {
    width: 20%;
    font-weight: bold;
    border: 1px solid black;  
}

.hierarchy-of-controls .center {
    width: 60%;
    border: 1px solid black;  
}



.consequence-definition-header {
  color: black !important;
} 

.risk-rating-table-header-white {
  background-color: white !important;
}

.likelihood-definition-table-border-none {
    border: none !important;
}

.Level of Health and Safety Protection-color {
    background-color: #d1af64 !important;
}



      .task-details-card {
        border-radius: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        margin-top: 20px;
        margin-bottom: 20px;
        border: 5px solid ${documentColor};
      }

      .task-details-card .card-header {
        background-color: ${documentColor};
        padding: 15px;
        color: #FFFFFF;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .task-details-card .header-content {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .task-details-card .card-content {
        background-color: #f8f9fa;
        padding: 20px;
      }

      .task-details-table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }

      .task-details-table td {
        padding: 10px;
        border: 1px solid #ddd;
      }

      .task-details-table .label-cell {
        width: 200px;
        background-color: ${documentColor};
        color: white;
        font-weight: bold;
      }

      .task-details-table .content-cell {
        background-color: white;
      }

      .task-details-table textarea.readonly-text {
        width: 100%;
        min-height: 60px;
        padding: 8px;
        border: none;
        background-color: transparent;
        resize: none;
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        cursor: default;
      }




        </style>
      </head>
      <body>


      <div class="header-card">
        <table class="header-table">
          <tr>
            <td class="logo-section">
              <img src="${documentData.documentLogo || defaultDocumentLogo}" 
                   alt="Document Logo" 
                   class="logo"
            </td>
            <td class="statement-section">
              <h2 class="statement-title">SAFE WORK METHOD STATEMENT</h2>
              <input type="text" class="input-area" value="${documentData.document_title || ""}" readonly />
            </td>
          </tr>
        </table>
      </div>



  <div class="task-details-card">
    <div class="card-header">
      <div class="header-content">
        <i class="fas fa-tasks"></i>
        <span>Task Details</span>
      </div>
      <i class="fas fa-chevron-up"></i>
    </div>

    <div class="card-content">
      <table class="task-details-table">
        <tr>
          <td class="label-cell">Task Details</td>
          <td class="content-cell">
            <textarea class="readonly-text" readonly>${documentData.task_details || ''}</textarea>
          </td>
        </tr>
        <tr>
          <td class="label-cell">Location and Site Details</td>
          <td class="content-cell">
            <textarea class="readonly-text" readonly>${documentData.location_details || ''}</textarea>
          </td>
        </tr>
        <tr>
          <td class="label-cell">Must Include Tasks</td>
          <td class="content-cell">
            <textarea class="readonly-text" readonly>${documentData.include_tasks || 'Optional'}</textarea>
          </td>
        </tr>
        <tr>
          <td class="label-cell">Must Exclude Tasks</td>
          <td class="content-cell">
            <textarea class="readonly-text" readonly>${documentData.exclude_tasks || 'Optional'}</textarea>
          </td>
        </tr>
        <tr>
          <td class="label-cell">Number of Activities</td>
          <td class="content-cell">${documentData.number_of_activities || '0'}</td>
        </tr>
        <tr>
          <td class="label-cell">State</td>
          <td class="content-cell">${documentData.selected_state || ''}</td>
        </tr>
      </table>
    </div>
  </div>





  <div class="project-details-card">
  <div class="card-header">
    <i class="fas fa-clipboard-list"></i>
    <span>Project Details</span>
    <i class="fas fa-chevron-up"></i>
  </div>

  <div class="card-content">
    <!-- First Section: SWMS and Location details -->
    <div class="yellow-border">
      <table class="form-table">
        <tr>
          <td class="form-field">
            <label for="swms-title">SWMS Title</label>
            <input type="text" id="swms-title" name="swms-title" 
              value="${
                documentData.project_details?.swmsTitle || ""
              }" readonly />
          </td>
          <td class="form-field small">
            <label for="swms-no">SWMS No</label>
            <input type="text" id="swms-no" name="swms-no" 
              value="${documentData.project_details?.swmsNo || ""}" readonly />
          </td>
        </tr>
        <tr>
          <td class="form-field">
            <label for="work-location">Work Location</label>
            <input type="text" id="work-location" name="work-location" 
              value="${
                documentData.project_details?.workLocation || ""
              }" readonly />
          </td>
          <td class="form-field small">
            <label for="rev-no">Rev No</label>
            <input type="text" id="rev-no" name="rev-no" 
              value="${documentData.project_details?.revNo || ""}" readonly />
          </td>
        </tr>
      </table>
    </div>

    <!-- Second Section: Remaining details -->
    <div class="yellow-border">
      <table class="form-table">
        <tr>
          <td class="form-field">
            <label for="project-name">Project Name</label>
            <input type="text" id="project-name" name="project-name" 
              value="${
                documentData.project_details?.projectName || ""
              }" readonly />
          </td>
          <td class="form-field small">
            <label for="date">Date</label>
            <div class="date-input">
              <input type="text" id="date" name="date" 
                value="${documentData.project_details?.date || ""}" readonly />
              <i class="fas fa-calendar-alt"></i>
            </div>
          </td>   
        </tr>
        <tr>
          <td class="form-field">
            <label for="contractor-name">Contractor Name</label>
            <input type="text" id="contractor-name" name="contractor-name" 
              value="${
                documentData.project_details?.contractorName || ""
              }" readonly />
          </td>
          <td class="form-field small">
            <label for="abn">ABN</label>
            <input type="text" id="abn" name="abn" 
              value="${documentData.project_details?.abn || ""}" readonly />
          </td>
        </tr>
        <tr>
          <td class="form-field full-width" colspan="2">
            <label for="address">Address</label>
            <input type="text" id="address" name="address" 
              value="${documentData.project_details?.address || ""}" readonly />
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>





<div class="high-level-risks-card"> 
  <div class="card-header schedule-card-header"> 
    <div class="header-left">
      <i class="fas fa-exclamation-triangle"></i>
      <span>High Level Risks</span>
    </div>
    <i class="fas fa-chevron-up"></i>
  </div>

  <div class="schedule-card-content">
    <table class="risk-table">
      <tbody>
        ${documentData.high_level_risks?.risks
          .map((risk, index) => {
            const startRow = index % 3 === 0;
            const endRow =
              index % 3 === 2 ||
              index === documentData.high_level_risks.risks.length - 1;

            return `
            ${startRow ? "<tr>" : ""}
              <td class="risk-card-wrapper">
                <div class="risk-card">
                  <div class="form-field">
                    <label for="high-level-risk-${
                      index + 1
                    }">High Level Risk</label>
                    <input
                      type="text"
                      id="high-level-risk-${index + 1}"
                      value="${index + 1}"
                      readonly
                    />
                  </div>
                  <div class="form-field">
                    <label for="name-of-risk-${
                      index + 1
                    }">Name of the Risk</label>
                    <textarea
                      id="name-of-risk-${index + 1}"
                      readonly>${risk.risk || ""}</textarea>
                  </div>
                  <div class="form-field">
                    <label for="explanation-${index + 1}">Explanation</label>
                    <textarea 
                      id="explanation-${index + 1}" 
                      class="auto-resize"
                      rows="20"
                      readonly>${risk.explanation || ""}</textarea>
                  </div>
                  <div class="form-field">
                    <label for="code-of-practice-${
                      index + 1
                    }">Code of Practice</label>
                    <textarea
                      id="code-of-practice-${index + 1}"
                      readonly
                      style="cursor: ${
                        risk.codeOfPractice &&
                        risk.codeOfPractice.startsWith("http")
                          ? "pointer"
                          : "auto"
                      };
                             color: ${
                               risk.codeOfPractice &&
                               risk.codeOfPractice.startsWith("http")
                                 ? "blue"
                                 : "inherit"
                             };
                             text-decoration: ${
                               risk.codeOfPractice &&
                               risk.codeOfPractice.startsWith("http")
                                 ? "underline"
                                 : "none"
                             };"
                      onclick="${
                        risk.codeOfPractice &&
                        risk.codeOfPractice.startsWith("http")
                          ? `window.open('${risk.codeOfPractice}', '_blank', 'noopener,noreferrer')`
                          : ""
                      }"
                    >${
                      risk.codeOfPractice &&
                      risk.codeOfPractice.startsWith("http")
                        ? extractKeywords(risk.codeOfPractice)
                        : risk.codeOfPractice || ""
                    }</textarea>
                  </div>
                </div>
              </td>
            ${endRow ? "</tr>" : ""}
          `;
          })
          .join("")}
      </tbody>
    </table>
  </div>
</div>





<div class="risk-management-schedule-card">
  <div class="card-header">
    <div class="header-content">
      <i class="fas fa-clipboard-list"></i>
      <span>Risk Management Schedule</span>
    </div>
    <i class="fas fa-chevron-up"></i>
  </div>

  <div class="card-content">
    ${documentData.risk_management_schedule?.sections.map(
      (section, index) => `
      <div class="outer-yellow-border"> <!-- Outer yellow border -->
        <div class="field-group">
          <table class="risk-schedule-table">
            <tr>
              <td class="field-small">
                <label for="no-${index}">No</label>
                <input type="text" id="no-${index}" name="no-${index}" 
                  value="${section.no || ''}" readonly />
              </td>
              <td class="field-large">
                <label for="activity-${index}">Activity</label>
                <input type="text" id="activity-${index}" name="activity-${index}" 
                  value="${section.activity || ''}" readonly />
              </td>
              <td class="field-medium">
                <label for="initial-risk-${index}">Initial Risk</label>
                <input type="text" id="initial-risk-${index}" 
                  name="initial-risk-${index}" 
                  value="${section.initialRisk || ''}" readonly 
                  style="background-color: ${getBackgroundColor(section.initialRisk)};" />
              </td>
              <td class="field-medium">
                <label for="hierarchy-${index}">Hierarchy</label>
                <input type="text" id="hierarchy-${index}" 
                  name="hierarchy-${index}" 
                  value="${section.hierarchy || ''}" readonly 
                  style="background-color: ${getHierarchyBackgroundColor(section.hierarchy)};" />
              </td>
            </tr>
          </table>
        </div>
        <div class="controls-group">
          <label for="controls-procedures-${index}">Controls and Procedures</label>
          <pre class="controls-procedures">${section.controlsProcedures ? section.controlsProcedures.trim() : ''}</pre>
        </div>
        <div class="field-group">
          <table class="risk-schedule-table">
            <tr>
              <td class="field-medium">
                <label for="residual-risk-${index}">Residual Risk</label>
                <input type="text" id="residual-risk-${index}" 
                  name="residual-risk-${index}" 
                  value="${section.residualRisk || ''}" readonly 
                  style="background-color: ${getBackgroundColor(section.residualRisk)};" />
              </td>
            <td colspan="3" class="field-large">
              <label for="persons-responsible-${index}">Persons Responsible</label>
              <textarea id="persons-responsible-${index}"
                class="risk-schedule-text-area"
                name="persons-responsible-${index}" 
                rows="2" 
                readonly>${section.personsResponsible || ''}</textarea>
            </td>
            </tr>
          </table>
        </div>
      </div> <!-- End of outer yellow border -->
    `).join('') || '<p>No risk management data available.</p>'}
  </div>
</div>


  <div class="persons-involved-card">
  <div class="card-header">
    <div class="header-left">
      <i class="fas fa-users"></i>
      <span>Persons Involved</span>
    </div>
    <i class="fas fa-chevron-up"></i>
  </div>

  <div class="card-content">
    <table class="persons-involved-table">
      ${
        documentData.persons_involved_card &&
        documentData.persons_involved_card.sections
          ? documentData.persons_involved_card.sections
              .map(
                (section, index) => `
          <tr class="personnel-row">
            <td class="form-column">
              <div class="inner-card-wrapper">
                <label>Job Title</label>
                <textarea rows=15 readonly>${
                  section.jobTitle || ""
                }</textarea>
              </div>
            </td>
            <td class="form-column">
              <div class="inner-card-wrapper">
                <label>Roles and Responsibilities</label>
                <textarea rows=15 readonly>${section.rolesResponsibilities.join(
                  "\n"
                )}</textarea>
              </div>
            </td>
            <td class="form-column">
              <div class="inner-card-wrapper">
                <label>Qualifications & Experience Required</label>
                <textarea rows=15 readonly>${section.qualificationsExperience.join(
                  "\n"
                )}</textarea>
              </div>
            </td>
          </tr>`
              )
              .join("")
          : '<tr><td colspan="3" class="no-data">No personnel data available.</td></tr>'
      }
    </table>
  </div>
</div>





<div class="plant-equipment-container">
  <div class="card-header">
    <div class="header-left">
      <i class="fas fa-wrench"></i>
      <span>Construction Plant and Equipment</span>
    </div>
    <i class="fas fa-chevron-up"></i>
  </div>
  
  <div class="card-content">
    <table class="persons-involved-table">
      ${documentData.construction_plant_equipment && documentData.construction_plant_equipment.sections ? 
        documentData.construction_plant_equipment.sections.map((section, index) => `
          <tr class="personnel-row">
            <td class="form-column">
              <div class="inner-card-wrapper">
                <label>Plant and Equipment Activity</label>
                <textarea rows=15 readonly>${section.plant_and_equipment || ''}</textarea>
              </div>
            </td>
            <td class="form-column">
              <div class="inner-card-wrapper">
                <label>Safety Maintenance</label>
                <textarea rows=15 readonly>${section.safety_consideration ? section.safety_consideration.join('\n') : ''}</textarea>
              </div>
            </td>
            <td class="form-column">
              <div class="inner-card-wrapper">
                <label>Relevant Standards and Regulations</label>
                <textarea rows=15 readonly>${section.standards_and_regulations ? section.standards_and_regulations.join('\n') : ''}</textarea>
              </div>
            </td>
          </tr>
        `).join('')
      : '<tr><td colspan="3" class="no-data">No construction plant and equipment data available.</td></tr>'}
    </table>
  </div>
</div>


<div class="legislation-card">
  <div class="card-header">
    <div class="header-left">
      <i class="fas fa-book"></i>
      <span>Legislative Reference and ACOPs</span>
    </div>
    <i class="fas fa-chevron-up"></i>
  </div>
  
  <div class="card-content">
    ${
      documentData.legislative_list && documentData.legislative_list.items
        ? documentData.legislative_list.items
            .map(
              (item, index) => `
        <div class="legislation-item-card">
          <table class="legislation-table">
            <tr>
              <td class="cell-label">
                <label for="number-${index}">Number</label>
                <input type="text" id="number-${index}" name="number-${index}" value="${
                item.number || ""
              }" readonly />
              </td>
            </tr>
            <tr>
              <td class="cell-label">
                <label>Legislative Reference and ACOPs</label>
                <div class="legislation-card-auto-grow-text">${
                  item.legislativeReference || ""
                }</div>
              </td>
            </tr>
            <tr>
              <td class="cell-label">
                <label>Why the Legislation and ACOPs Apply</label>
                <textarea 
                  class="legislation-card-auto-grow-text" 
                  readonly 
                  oninput="autoGrow(this)" 
                  rows="7"
                  style="overflow: hidden; resize: none;">${
                    item.whyLegislation || ""
                  }</textarea>
              </td>
            </tr>
          </table>
        </div>
      `
            )
            .join("")
        : "<p>No legislative reference data available.</p>"
    }
  </div>
</div>



<div class="ppe-card-container">
  <div class="card-header">
    <div class="header-left">
      <i class="fas fa-hard-hat"></i>
      <span>Personal Protective Equipment</span>
    </div>
    <i class="fas fa-chevron-up"></i>
  </div>
  
  <div class="card-content">
    <table class="persons-involved-table">
      ${documentData.ppe_card && documentData.ppe_card.ppeList ? 
        documentData.ppe_card.ppeList.map((item, index) => `
          <tr class="personnel-row">
            <td class="form-column">
              <div class="inner-card-wrapper">
                <label>Item</label>
                <textarea rows=7 readonly>${item.activity || ''}</textarea>
              </div>
            </td>
            <td class="form-column">
              <div class="inner-card-wrapper">
                <label>Description</label>
                <textarea rows=7 readonly>${item.ppeDescription || ''}</textarea>
              </div>
            </td>
            <td class="form-column">
              <div class="inner-card-wrapper">
                <label>AS/NZS Standards</label>
                <textarea rows=7 readonly>${item.australianStandards || ''}</textarea>
              </div>
            </td>
          </tr>
        `).join('')
      : '<tr><td colspan="3" class="no-data">No personal protective equipment data available.</td></tr>'}
    </table>
  </div>
</div>




<div class="permit-card-container">
  <div class="permit-card-header">
    <div class="header-content">
      <i class="fas fa-exclamation-triangle"></i>
      <span>Required Permits</span>
    </div>
    <i class="fas fa-chevron-up"></i>
  </div>

  <div class="permit-card-body">
    ${(() => {
      const permitsData =
        documentData.required_permits ||
        documentData.requiredPermits ||
        documentData.permits;

      if (permitsData && Array.isArray(permitsData)) {
        return permitsData
          .map(
            (item, index) => `
          <div class="permit-item-card">
            <table class="permit-table">
              <tr>
                <td class="permit-cell">
                  <label for="permit-number-${index}">Number</label>
                  <input type="text" id="permit-number-${index}" name="permit-number-${index}" value="${
              item.number || ""
            }" readonly />
                </td>
                <td class="permit-cell">
                  <label for="permit-name-${index}">Permit Name</label>
                  <input type="text" id="permit-name-${index}" name="permit-name-${index}" value="${
              item.name || item.permitName || ""
            }" readonly />
                </td>
              </tr>
              <tr>
              <td class="permit-cell" colspan="2">
                <label>How to Acquire Permit</label>
                <div class="auto-grow-text">${
                  (item.howToAcquire || item.acquisition || "")
                    .map((line, lineIndex) => {
                      // Check if the line starts with a number followed by a dot
                      if (/^\d+\./.test(line.trim())) {
                        return line; // Keep existing numbered lines as they are
                      } else if (line.trim()) {
                        // Add numbers to non-empty lines that don't have numbers
                        return `${lineIndex + 1}. ${line.trim()}`;
                      }
                      return line; // Return empty lines as-is
                    })
                    .join('\n')
                }</div>
              </td>
              </tr>
              <tr>
                <td class="permit-cell" colspan="2">
                  <label>Why the Permit is Required</label>
                  <div class="auto-grow-text">${
                    item.whyRequired || item.reason || ""
                  }</div>
                </td>
              </tr>
            </table>
          </div>
        `
          )
          .join("");
      } else {
        return "<p>No required permits data available.</p>";
      }
    })()}
  </div>
</div>




<div class="risk-tables-card">
      <div class="card-header">
        <div class="header-left">
          <i class="fas fa-table"></i>
          <span>Risk Tables</span>
        </div>
        <i class="fas fa-chevron-up"></i>
      </div>


<div class="risk-rating-table">
  <h3 class="risk-rating-table-header-black">RISK AND OPPORTUNITY RATING TABLES</h3>
  <h3 class="risk-rating-table-header-black">RISK</h3>
  <table>
    <thead>
      <tr>
        <th class="risk-rating-table-header" colspan="3" rowspan="2">RISK RATING TABLE</th>
        <th class="consequence-header" colspan="5">CONSEQUENCE</th>
      </tr>
      <tr>
        <th>Insignificant<br/>1</th>
        <th>Minor<br/>2</th>
        <th>Moderate<br/>3</th>
        <th>Major<br/>4</th>
        <th>Catastrophic<br/>5</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="risk-rating-cell-A likelihood-cell risk-rating-table-header-white" rowspan="5">LIKELIHOOD</td>
        <td class="risk-rating-cell-A">Almost Certain</td>
        <td class="risk-rating-cell-A">A</td>
        <td class="yellow">H-11</td>
        <td class="orange">H-16</td>
        <td class="red">E-20</td>
        <td class="red">E-23</td>
        <td class="red">E-25</td>
      </tr>
      <tr>
        <td class="risk-rating-cell-A">Likely</td>
        <td class="risk-rating-cell-A">B</td>
        <td class="yellow">M-7</td>
        <td class="orange">H-12</td>
        <td class="orange">H-17</td>
        <td class="red">E-21</td>
        <td class="red">E-24</td>
      </tr>
      <tr>
        <td class="risk-rating-cell-A">Possible</td>
        <td class="risk-rating-cell-A">C</td>
        <td class="green">L-4</td>
        <td class="yellow">M-8</td>
        <td class="orange">H-13</td>
        <td class="red">E-18</td>
        <td class="red">E-22</td>
      </tr>
      <tr>
        <td class="risk-rating-cell-A">Unlikely</td>
        <td class="risk-rating-cell-A">D</td>
        <td class="green">L-2</td>
        <td class="green">L-5</td>
        <td class="yellow">M-9</td>
        <td class="orange">H-14</td>
        <td class="red">E-19</td>
      </tr>
      <tr>
        <td class="risk-rating-cell-A">Rare</td>
        <td class="risk-rating-cell-A">E</td>
        <td class="green">L-1</td>
        <td class="green">L-3</td>
        <td class="yellow">M-6</td>
        <td class="orange">H-10</td>
        <td class="orange">H-15</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="likelihood-definition">
  <h3 class="risk-rating-table-header-black">LIKELIHOOD DEFINITION SUMMARY</h3>
  <table>
    <thead>
      <tr class="likelihood-definition-table-border-none">
        <th colspan="4" class="likelihood-definition-header-content">
          <div class="likelihood-definition-header-content">
            <span>Probability (Frequency)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Description</span>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="likelihood-cell-weight">A</td>
        <td class="likelihood-cell-weight">Almost certain</td>
        <td class="no-right-border">Occurs more than once a month</td>
        <td class="no-left-border">The event is expected to occur in most circumstances</td>
      </tr>
      <tr>
        <td class="likelihood-cell-weight">B</td>
        <td class="likelihood-cell-weight">Likely</td>
        <td class="no-right-border">Occurs once every month to 1 year</td>
        <td class="no-left-border">The event will probably occur in most circumstances</td>
      </tr>
      <tr>
        <td class="likelihood-cell-weight">C</td>
        <td class="likelihood-cell-weight">Medium</td>
        <td class="no-right-border">Occurs once every 1 year to 5 years</td>
        <td class="no-left-border">The event should occur at some times</td>
      </tr>
      <tr>
        <td class="likelihood-cell-weight">D</td>
        <td class="likelihood-cell-weight">Unlikely</td>
        <td class="no-right-border">Occurs once every 5 years to 10 years</td>
        <td class="no-left-border">The event could occur at some times</td>
      </tr>
      <tr>
        <td class="likelihood-cell-weight">E</td>
        <td class="likelihood-cell-weight">Rare</td>
        <td class="no-right-border">Occurs less than once every 10 years</td>
        <td class="no-left-border">The event may occur only in exceptional circumstances</td>
      </tr>
    </tbody>
  </table>
</div>

        <div class="risk-level-action">
          <h3 class="risk-rating-table-header-black">RISK LEVEL ACTION TABLE</h3>
          <table>
            <tr class="extreme">
              <td class="risk-action-table-header-color-white risk-level-action-table-middle-text-weight-normal">EXTREME<br />(Unacceptable)</td>
              <td>
                Immediate action required - High level of supervision and
                monitoring of controls must be added with senior management
                responsibility. i.e. Increase inspection monitoring and added to
                the daily inspection process. A job hazard analysis must be
                undertaken with all relevant workers, including review of
                control methods and use of relevant permits to work as
                applicable.
              </td>
            </tr>
            <tr class="high">
              <td class="risk-action-table-header-color-white risk-level-action-table-middle-text-weight-normal">HIGH<br />(Undesirable)</td>
              <td>
                Site management attention needed with safety, quality, and
                environmental responsibilities communicated and delegated at
                management level. Take all reasonable steps to eliminate the
                risk or minimise it by introducing substitution, isolation, or
                engineering controls as soon as possible.
              </td>
            </tr>
            <tr class="moderate">
              <td class="risk-level-action-table-middle-text-weight-normal">MODERATE<br />(Tolerable)</td>
              <td>
                Site management attention needed with safety, quality, and
                environmental responsibilities communicated and delegated at
                management level. Take all reasonable steps to eliminate the
                risk or minimise it by introducing substitution, isolation, or
                engineering controls as soon as possible.
              </td>
            </tr>
            <tr class="low">
              <td >LOW<br />(Acceptable)</td>
              <td>
                Manage by routine procedures such as safe work method
                statements, communication and consultation processes on a
                regular basis at individuals. Implementation of control measures
                should decrease the risk to as low as reasonably practicable.
              </td>
            </tr>
          </table>
        </div>

 <div class="hierarchy-of-controls">
          <table>
            <tr class="header">
              <td colspan="3">HIERARCHY of CONTROLS</td>
            </tr>
            <tr class="level-1">
              <td class="side hierarchy-of-controls-level-3-middle-text-weight-normal" rowspan="2">Highest</td>
              <td class="center">
                <div class="hierarchy-of-controls-level-3-middle-text-weight-normal">LEVEL 1</div>
              </td>
              <td class="side hierarchy-of-controls-level-3-middle-text-weight-normal" rowspan="2">Most</td>
            </tr>
            <tr class="level-1">
              <td class="center">Eliminate the Hazard</td>
            </tr>
            <tr class="level-2">
              <td class="side hierarchy-of-controls-level-3-middle-text-weight-normal Level of Health and Safety Protection-color" rowspan="4">
                Level of Health and Safety Protection
              </td>
              <td class="center">
                <div class="hierarchy-of-controls-level-3-middle-text-weight-normal">LEVEL 2</div>
              </td>
              <td class="side hierarchy-of-controls-level-3-middle-text-weight-normal Level of Health and Safety Protection-color" rowspan="4">Reliability of Control Measures</td>
            </tr>
            <tr class="level-2">
              <td class="center">Substitute the hazard with something safer</td>
            </tr>
            <tr class="level-2">
              <td class="center">Isolate the hazard from people</td>
            </tr>
            <tr class="level-2">
              <td class="center">
                Reduce the risk through engineering controls
              </td>
            </tr>
            <tr class="level-3">
              <td class="side" rowspan="3">Lowest</td>
              <td class="center">
                <div class="hierarchy-of-controls-level-3-black hierarchy-of-controls-level-3-middle-text-weight-normal">LEVEL 3</div>
              </td>
              <td class="side" rowspan="3">Least</td>
            </tr>
            <tr class="level-3">
              <td class="center">
                <div class="hierarchy-of-controls-level-3-black hierarchy-of-controls-level-3-middle-text-weight-normal">
                  Reduce the exposure to the hazard using administrative actions
                </div>
              </td>
            </tr>
            <tr class="level-3">
              <td class="center">
                <div class="hierarchy-of-controls-level-3-black hierarchy-of-controls-level-3-middle-text-weight-normal">
                  Use personal protective equipment
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="consequence-definition">
          <h3 class="consequence-definition-header">CONSEQUENCE DEFINITION SUMMARY</h3>
          <table>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th class="consequence-definition-header">PEOPLE/HEALTH AND SAFETY</th>
                <th class="consequence-definition-header">ENVIRONMENTAL</th>
                <th class="consequence-definition-header">FINANCIAL ASSET</th>
                <th class="consequence-definition-header">COMMUNITY/ REPUTATION</th>
                <th class="consequence-definition-header">STATUTORY/ LEGAL</th>
                <th class="consequence-definition-header">DELAY</th>
                <th class="consequence-definition-header">CONFORMITY/QUALITY</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>5</td>
                <td>CATASTROPHIC</td>
                <td>Death, permanent disablement, etc.</td>
                <td>
                  Permanent environmental damage, Endangered species and habitat
                  destroyed
                </td>
                <td>
                  Loss >$250K, Irreparable damage to the product, service,
                  outcome
                </td>
                <td>
                  Severe impact on the community, services and property,
                  International scale negative media attention
                </td>
                <td>Criminal prosecution, Serious litigation, Major fines</td>
                <td>Indefinite Delay or Stoppage</td>
                <td>
                  The risks can cause damage to the infrastructure that cannot
                  be repaired and affect permanently its function
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>MAJOR</td>
                <td>
                  Serious body injury or illness - LTI, Potential serious LTI,
                  multiple LTIs
                </td>
                <td>
                  Major damage to the environment, Long term effect, Damage to
                  protected species or habitat
                </td>
                <td>Loss $100K - $250K, Serious damage to product</td>
                <td>
                  Major impact on the community, services and property, National
                  public or media negative attention
                </td>
                <td>Prosecution, major fines</td>
                <td>Between 3 - 6 months</td>
                <td>
                  Damage or effects in the functionality of the infrastructure
                  may occur that can be offset with complex measures, with
                  significant impact on the cost
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>MODERATE</td>
                <td>
                  Injury or Illness requiring casualty treatment - MTI,
                  Potential LTI
                </td>
                <td>
                  Serious damage to the environment, Medium term effect,
                  Protected species or habitat involved
                </td>
                <td>
                  Medium term Loss $25K - $100K, Repairable damage to product
                </td>
                <td>
                  Serious impact on the community, services and property, State
                  media attention
                </td>
                <td>Prohibition Notice, fines</td>
                <td>Between 1 - 3 months</td>
                <td>
                  Damage or effects in the functionality of the infrastructure
                  may occur that can be repaired or require increased
                  maintenance assumable for the company
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>MINOR</td>
                <td>Injury/Illness requiring first aid treatment - FAI</td>
                <td>Minor damage to the environment, Within site boundaries</td>
                <td>
                  Loss $5K - $25K, Minor damage to product, service, outcome
                </td>
                <td>
                  Minor adverse local public or media attention or complaints
                </td>
                <td>Improvement and Infringement notice</td>
                <td>Between 1 week and 4 weeks</td>
                <td>
                  Damage or effects in the functionality of the infrastructure
                  may occur that can be repaired at low costs for the company,
                  without effect in the maintenance
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>INSIGNIFICANT</td>
                <td>Potential MTI</td>
                <td>Negligible damage to the environment</td>
                <td>Repairable damage to product, service, outcome</td>
                <td>
                  Community complaints with no corrective action Visit from
                  Regulators with verbal
                </td>
                <td>No comments of OFI</td>
                <td>No impact or less than 1 week</td>
                <td>
                  No effects are produced on the functionality of the
                  infrastructure
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="employee-induction-card">
      <div class="card-header">
        <div class="header-content">
          <div class="header-left">
            <i class="fas fa-user-check"></i>
            <span>Employee induction– Statement of agreement</span>
          </div>
          <p class="agreement-text">
            On signing this SWMS, I agree that I have been consulted,
            understand, and agree to abide by the control measures listed in all
            the pages contained within this SWMS.
          </p>
        </div>
        <i class="fas fa-chevron-up"></i>
      </div>
      <div class="card-content">
        <div class="induction-table">
          <h3>Induction Sign On Page</h3>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Company</th>
                <th>Signature</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              ${documentData.induction_sign_on?.rowsPage1.map((row, index) => `
                <tr>
                  <td><input type="text" value="${row.name}" readonly /></td>
                  <td><input type="text" value="${row.company}" readonly /></td>
                  <td><input type="text" value="${row.signature}" readonly /></td>
                  <td><input type="date" value="${row.date}" readonly /></td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- People involved in the development of this SWMS -->
    <div class="people-involved-card">
      <div class="card-header">
        <div class="header-left">
          <i class="fas fa-users"></i>
          <span>People involved in the development of this SWMS</span>
        </div>
        <i class="fas fa-chevron-up"></i>
      </div>
      <div class="card-content">
        <table>
          <thead>
            <tr>
              <th>Name of the person</th>
              <th>Role of the person</th>
            </tr>
          </thead>
          <tbody>
            ${documentData.people_involved?.rowsPage1.map((row, index) => `
              <tr>
                <td><input type="text" value="${row.name}" readonly /></td>
                <td><input type="text" value="${row.role}" readonly /></td>
              </tr>
            `).join('')}
          </tbody>
        </table>
      </div>
    </div>

    <!-- Form Reviewer -->
    <div class="form-reviewer-card">
      <div class="card-header">
        <div class="header-left">
          <i class="fas fa-clipboard-check"></i>
          <span>Form Reviewer</span>
        </div>
        <i class="fas fa-chevron-up"></i>
      </div>
      <div class="card-content">
        <table>
          <thead>
            <tr>
              <th>Position</th>
              <th>Company</th>
              <th>Print name</th>
              <th>Signature</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            ${documentData.form_reviewer?.rows_page1.map((row, index) => `
              <tr>
                <td><input type="text" value="${row.reviewed_by}" readonly /></td>
                <td><input type="text" value="${row.company}" readonly /></td>
                <td><input type="text" value="${row.print_name}" readonly /></td>
                <td><input type="text" value="${row.signature}" readonly /></td>
                <td><input type="date" value="${row.date}" readonly /></td>
              </tr>
            `).join('')}
          </tbody>
        </table>
      </div>
    </div>


  </body>
    <script src="https://kit.fontawesome.com/your-fontawesome-kit.js" crossorigin="anonymous">
    
      function autoGrow(textarea) {
  textarea.style.height = 'auto'; // Reset height to auto to calculate the new height
  textarea.style.height = textarea.scrollHeight + 'px'; // Set height to the scrollHeight
}

// Automatically grow text areas on page load
document.querySelectorAll('textarea').forEach(textarea => {
  autoGrow(textarea); // Call autoGrow for each textarea on load
});

    </script>
    </html>
    `;
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300">
      <div className="flex items-center mb-4">
        <FaFile className="text-blue-500 mr-3 text-xl" />
        <h3 className="text-xl font-bold text-gray-800">{docData.project_details?.swmsTitle || 'Untitled Document'}</h3>
      </div>
      <div className="text-gray-600 mb-2 flex items-center">
        <FaProjectDiagram className="mr-2 text-green-500" />
        <span>Project: {docData.project_details?.projectName || 'Unnamed Project'}</span>
      </div>
      <div className="text-gray-600 mb-2 flex items-center">
        <FaFileAlt className="mr-2 text-purple-500" />
        <span>SWMS No: {docData.project_details?.swmsNo || 'N/A'}</span>
      </div>
      <div className="text-gray-600 mb-2 flex items-center">
        <FaCalendar className="mr-2" />
        <span>Created: {new Date(docData.created_at).toLocaleDateString()}</span>
      </div>
      <div className="text-gray-600 mb-4 flex items-center">
        <FaCoins className="mr-2 text-yellow-500" />
        <span>Tokens: {docData.tokens_used}</span>
      </div>
      <button
        onClick={() => handleDownloadPDF(docData.id)}
        className="w-full flex items-center justify-center bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-200 ease-in-out"
      >
        <Download className="w-4 h-4 mr-2" /> Download PDF
      </button>
      {downloadError && <div className="text-red-500 mt-2 text-sm">{downloadError}</div>}
    </div>
  );
};

function UserDocuments() {
  const [user, setUser] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userId } = useParams();
  const { authToken, user: currentUser } = useAuth();
  const isAdmin = currentUser?.is_superadmin === true;

  useEffect(() => {
    const fetchUserDocuments = async () => {
      try {
        const response = await fetch(`https://www.icromm.com/api/admin/users/${userId}`, {
          headers: {
            ...(isAdmin ? {} : { Authorization: `Bearer ${authToken}` }),
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch user documents');
        }
        const data = await response.json();
        setUser(data.user);
        setDocuments(data.documents);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchUserDocuments();
  }, [userId, authToken, isAdmin]);

  if (loading) return <div className="text-center mt-8">Loading...</div>;
  if (error) return <div className="text-center mt-8 text-red-500">Error: {error}</div>;

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <Link to="/admin-dashboard/all-users" className="flex items-center text-blue-500 hover:text-blue-700 mb-6">
        <FaArrowLeft className="mr-2" />
        Back to All Users
      </Link>
      <h2 className="text-3xl font-bold mb-6 text-gray-800">
        Documents for {user.first_name} {user.last_name}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {documents.map((doc) => (
          <DocumentCard 
            key={doc.id} 
            document={doc} 
            isAdmin={isAdmin} 
            authToken={authToken}
          />
        ))}
      </div>
    </div>
  );
}

export default UserDocuments;
