import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Box,
  CircularProgress,
  TextareaAutosize,
  Typography,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { SketchPicker } from "react-color";
import PaletteIcon from "@mui/icons-material/Palette";
import { ArrowBigLeft, Bot } from "lucide-react";
import CloseIcon from "@mui/icons-material/Close";
import { Close } from "@mui/icons-material";
import qs from "qs";
import axios from "axios";
import { lighten, darken } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { FormContext } from '../../context/FormContext'; // Add this import

const ColorPickerAndApiModal = ({
  open,
  onClose,
  onColorChange,
  color,
  setQ1,
  setQ2,
  setQ3,
  setQ4,
  setQ5,
  setQ6,
  setQ7,
  loading,
  setLoading,
  setPercentage,
  handleSaveDocument,
  handleUpdateDocument,
}) => {
  const { documentColor, setDocumentColor, formMetadata } = useContext(FormContext);
  const [showSketchPicker, setShowSketchPicker] = useState(false);
  const [formValues, setFormValues] = useState({
    task: formMetadata.taskDetails || '',
    location: formMetadata.locationDetails || '',
    includeTask: formMetadata.includeTasks || '',
    excludeTask: formMetadata.excludeTasks || '',
    numberOfActivities: formMetadata.numberOfActivities || 22,
    state: formMetadata.selectedState || '',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [min, setMin] = useState(12);
  const [max, setMax] = useState(26);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setFormValues({
      task: formMetadata.taskDetails || '',
      location: formMetadata.locationDetails || '',
      includeTask: formMetadata.includeTasks || '',
      excludeTask: formMetadata.excludeTasks || '',
      numberOfActivities: formMetadata.numberOfActivities || 22,
      state: formMetadata.selectedState || '',
    });
  }, [formMetadata]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleColorChange = (newColor) => {
    // Update both the local state and the context
    onColorChange(newColor);
    setDocumentColor(newColor.hex);
  };

  const handleClose = () => {
    setShowSketchPicker(false);
    onClose();
  };

  const handleSubmit = async () => {
    if (formValues.task === "" || formValues.location === "") {
      setSnackbarMessage("Kindly fill the task and location fields");
      setSnackbarOpen(true);
      return;
    }

    if (!/^\d+$/.test(formValues.numberOfActivities)) {
      setSnackbarMessage(
        `Number of activities should be between ${min} and ${max}`
      );
      setSnackbarOpen(true);
      return;
    }

    if (
      formValues.numberOfActivities < min ||
      formValues.numberOfActivities > max
    ) {
      setSnackbarMessage(
        `Number of activities should be between ${min} and ${max}`
      );
      setSnackbarOpen(true);
      return;
    }

    if (!formValues.state) {
      setSnackbarMessage("Please select a state");
      setSnackbarOpen(true);
      return;
    }

    try {
      const params = new URLSearchParams({
        task: formValues.task,
        location: formValues.location,
        includeTask: formValues.includeTask || '',
        excludeTask: formValues.excludeTask || '',
        n: formValues.numberOfActivities.toString(),
        state_AU: formValues.state
      });

      const eventSource = new EventSource(
        `https://www.icromm.com/api/process_questions/?${params.toString()}`
      );
      let messageCount = 0;
      setLoading(true);
      setPercentage(0);

      eventSource.onmessage = (event) => {
        try {
          const jsonData = JSON.parse(event.data);
          if (jsonData.status === "complete") {
            // console.log("All messages have been received.");
            eventSource.close();
            setLoading(false);
            handleClose();
            return;
          }
          const jsonData1 = JSON.parse(jsonData);
          // console.log("jsondata 1: ", jsonData1);

          messageCount++;
          switch (messageCount) {
            case 1:
              setQ1(jsonData1.initialRiskTable);
              setPercentage(1);
              break;
            case 2:
              setQ2(jsonData1);
              setPercentage(2);
              break;
            case 3:
              setQ3(jsonData1);
              setPercentage(3);
              break;
            case 4:
              setQ4(jsonData1);
              setPercentage(4);
              break;
            case 5:
              setQ5(jsonData1);
              setPercentage(5);
              break;
            case 6:
              setQ6(jsonData1);
              setPercentage(6);
              break;
            case 7:
              setQ7(jsonData1);
              setPercentage(7);
              break;
            default:
              break;
          }
        } catch (error) {
          console.error("Error parsing event data as JSON:", error);
        }
      };

      eventSource.onerror = (error) => {
        console.error("EventSource failed:", error);
        eventSource.close();
        setLoading(false);
      };

      // Remove or comment out these lines that were causing the navigation
      // if (typeof handleUpdateDocument === "function") {
      //   await handleUpdateDocument(id, { color: documentColor });
      // } else {
      //   await handleSaveDocument(documentColor);
      // }

      // Only close the dialog when processing is complete
      // handleClose();
    } catch (error) {
      console.error("Error during API call:", error);
      setLoading(false);
      setSnackbarMessage("Error processing request. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleSaveAndGoToDashboard = async () => {
    if (typeof handleUpdateDocument === "function") {
      try {
        setLoading(true);
        await handleUpdateDocument(id, { color: documentColor });
        setLoading(false);
        navigate("/user-dashboard/all-documents");
      } catch (error) {
        console.error("Error updating document:", error);
        setLoading(false);
        setSnackbarMessage("Error updating document. Please try again.");
        setSnackbarOpen(true);
      }
    } else {
      navigate("/user-dashboard/all-documents");
    }
  };

  // Add Australian states array
  const australianStates = [
    "Queensland",
    "New South Wales",
    "Victoria",
    "Western Australia",
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      sx={{ borderRadius: "3px" }}
    >
      <DialogTitle sx={{ bgcolor: color, borderRadius: "3px 3px 0 0" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <IconButton
            sx={{
              padding: 0,
              color: "black",
              mr: 1, // Add margin-right to space out from the text
            }}
          >
            <Bot />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: "bold",
            }}
          >
            SWMS Autocomplete App
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          {/* <IconButton
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setShowSketchPicker(!showSketchPicker)}
          >
            <Box
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                backgroundColor: documentColor,
                border: "2px solid #DDDDDD",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  "& .MuiSvgIcon-root": {
                    opacity: 1, // Show icon on hover
                  },
                },
              }}
            >
              <PaletteIcon
                sx={{
                  fontSize: "18px",
                  color: "black",
                  // opacity: 0,
                }}
              />
            </Box>
          </IconButton> */}
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          borderLeft: `4px solid ${documentColor}`,
          borderRight: `4px solid ${documentColor}`,
          mb: 0,
          paddingBottom: 1,
          paddingLeft: 2,
          paddingRight: 2,
          bgcolor: "#E9EAEC",
        }}
      >
        <Box sx={{ mb: 2, position: "absolute", zIndex: 9, right: 10 }}>
          {showSketchPicker && (
            <SketchPicker
              color={color}
              onChange={handleColorChange}
              disableAlpha={true}
            />
          )}
        </Box>
        <Box
          sx={{
            border: `2px solid ${documentColor}`,
            padding: 2,
            borderRadius: "3px",
            mb: 0,
            mt: 2,
            position: "relative",
          }}
        >
          <form>
            <InputLabel
              htmlFor={`task`}
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#424242",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Task Details
            </InputLabel>
            <TextareaAutosize
              id={`task`}
              name="task"
              value={formValues.task}
              placeholder="Providing a comprehensive and detailed scope of work here ensures a more accurate and tailored Safe Work Method Statement (SWMS) document, reflecting the specific risks and controls relevant to the task you want to cover in this SWMS document."
              onChange={handleFormChange}
              minRows={3}
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "8px",
                borderRadius: "5px",
                borderColor: "grey",
                fontFamily: "Roboto, sans-serif",
              }}
            />
            <InputLabel
              htmlFor={`location`}
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#424242",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Location and Site Details
            </InputLabel>
            <TextareaAutosize
              id={`location`}
              name="location"
              value={formValues.location}
              onChange={handleFormChange}
              placeholder="Provide specific and detailed information about the site location and any relevant environmental factors or restrictions. Include descriptions of methods, plants, and work details unique to the site. Any special considerations, like using specific machinery or safety protocols, should be clearly outlined. The more precise the details, the more tailored the safety measures can be."
              minRows={3}
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "8px",
                borderRadius: "5px",
                borderColor: "grey",
                fontFamily: "Roboto, sans-serif",
              }}
            />
            <InputLabel
              htmlFor={`includeTask`}
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#424242",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Must Include Tasks (Optional)
            </InputLabel>
            <TextareaAutosize
              id={`includeTask`}
              name="includeTask"
              value={formValues.includeTask}
              onChange={handleFormChange}
              placeholder="All construction sites are unique, so it’s important to list the essential tasks that must be included in your SWMS to address your project's specific needs. Be specific about activities you want listed or compliance procedures that are critical for this job. For example, tasks might include special operational procedures, specific protocols, or safety measures for hazardous activities."
              minRows={3}
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "8px",
                borderRadius: "5px",
                borderColor: "grey",
                fontFamily: "Roboto, sans-serif",
              }}
            />
            <InputLabel
              htmlFor={`excludeTask`}
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#424242",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Must Exclude Tasks (Optional)
            </InputLabel>
            <TextareaAutosize
              id={`excludeTask`}
              name="excludeTask"
              value={formValues.excludeTask}
              onChange={handleFormChange}
              placeholder="Sometimes in your SWMS, you may want to exclude certain tasks that will be covered in a different SWMS document. For example, you might want to exclude scaffolding work or working with power tools to keep this document focused. Be clear about which tasks you intend to leave out so the SWMS remains relevant to the specific activities it covers."
              minRows={3}
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "8px",
                borderRadius: "5px",
                borderColor: "grey",
                fontFamily: "Roboto, sans-serif",
              }}
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ flex: 1 }}>
                <InputLabel
                  htmlFor={`numberOfActivities`}
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#424242",
                    fontFamily: "Roboto, sans-serif",
                  }}
                >
                  Number of Activities
                </InputLabel>
                <TextField
                  type="number"
                  name="numberOfActivities"
                  value={formValues.numberOfActivities}
                  onChange={handleFormChange}
                  variant="outlined"
                  size="small"
                  inputProps={{ min: min, max: max }}
                  sx={{
                    width: "100%",
                    bgcolor: "white",
                    borderRadius: "5px",
                    fontFamily: "Roboto, sans-serif",
                  }}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <InputLabel
                  htmlFor="state"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#424242",
                    fontFamily: "Roboto, sans-serif",
                  }}
                >
                  Select State
                </InputLabel>
                <TextField
                  select
                  name="state"
                  value={formValues.state}
                  onChange={handleFormChange}
                  variant="outlined"
                  size="small"
                  sx={{
                    width: "100%",
                    bgcolor: "white",
                    borderRadius: "5px",
                    fontFamily: "Roboto, sans-serif",
                  }}
                >
                  {australianStates.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Box>
          </form>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          borderLeft: `4px solid ${documentColor}`,
          borderRight: `4px solid ${documentColor}`,
          borderBottom: `4px solid ${documentColor}`,
          borderRadius: "0 0 3px 3px",
          bgcolor: "#E9EAEC",
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={handleSaveAndGoToDashboard}
            sx={{
              bgcolor: documentColor,
              fontFamily: "Roboto, sans-serif",
              color: "#f0f0f0",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              minWidth: "unset",
              width: "45px",
              height: "45px",
              padding: 0,
              "&:hover": {
                boxShadow: 10,
              },
              "&:active": {
                boxShadow: 2,
                bgcolor: "#d0d0d0",
              },
            }}
          >
            <ArrowBigLeft size={48} bgcolor="#f0f0f0" fill="#f0f0f0" />
          </Button>
          <Typography
            sx={{
              marginLeft: "10px",
              fontFamily: "Roboto, sans-serif",
              fontSize: "15px",
              fontWeight: "medium",
              color: "black",
            }}
          >
            Back to Dashboard
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={handleClose}
            sx={{
              bgcolor: color,
              fontFamily: "Roboto, sans-serif",
              color: "black",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
              marginRight: 1,
            }}
          >
            Close
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            sx={{
              bgcolor: loading ? lighten(documentColor, 0.2) : documentColor,
              fontFamily: "Roboto, sans-serif",
              color: "black",
              borderRadius: "3px",
              textTransform: "none",
              boxShadow: loading ? 1 : 3,
              "&:hover": {
                boxShadow: 4,
              },
              "&:active": {
                boxShadow: 2,
              },
            }}
          >
            {loading ? (
              <>
                <CircularProgress
                  size={24}
                  sx={{ color: darken(color, 0.4) }}
                />
                <Typography
                  sx={{ marginLeft: "5px", color: darken(documentColor, 0.4) }}
                >
                  Submit
                </Typography>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ColorPickerAndApiModal;
